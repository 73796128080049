<template>
    <v-card class="rounded-l">
        <v-card-text>
            <v-container fluid>
                <v-row justify="center">
                    <v-col cols="10">
                        <h5 class="text-center text-weight" style="font-weight:600">
                            8 {{$t('labels.floor')}}
                        </h5>
                    </v-col>
                </v-row>
                <v-row class="mt-12 mb-12">
                    <v-col xs="5" sm="5" md="5">
                        <v-row>
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_801 ? r_801 : ''"
                                    @click="pilihKamar(801, r_801_stat)"
                                >
                                    <div class="text-tengah" :class="text_801 ? text_801 : ''">0801</div>
                                </v-btn>
                            </v-col>    
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_803 ? r_803 : ''"
                                    @click="pilihKamar(803, r_803_stat)"
                                >
                                    <div class="text-tengah" :class="text_803 ? text_803 : ''">0803</div>
                                </v-btn>
                            </v-col>  
                            <v-col cols="12" class="hidden-md-and-up">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col cols="12" class="hidden-md-and-up">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col>     
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_805 ? r_805 : ''"
                                    @click="pilihKamar(805, r_805_stat)"
                                >
                                    <div class="text-tengah" :class="text_805 ? text_805 : ''">0805</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_807 ? r_807 : ''"
                                    @click="pilihKamar(807, r_807_stat)"
                                >
                                    <div class="text-tengah" :class="text_807 ? text_807 : ''">0807</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_809 ? r_809 : ''"
                                    @click="pilihKamar(809, r_809_stat)"
                                >
                                    <div class="text-tengah" :class="text_809 ? text_809 : ''">0809</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_811 ? r_811 : ''"
                                    @click="pilihKamar(811, r_811_stat)"
                                >
                                    <div class="text-tengah" :class="text_811 ? text_811 : ''">0811</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_815 ? r_815 : ''"
                                    @click="pilihKamar(815, r_815_stat)"
                                >
                                    <div class="text-tengah" :class="text_815 ? text_815 : ''">0815</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_817 ? r_817 : ''"
                                    @click="pilihKamar(817, r_817_stat)"
                                >
                                    <div class="text-tengah" :class="text_817 ? text_817 : ''">0817</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_819 ? r_819 : ''"
                                    @click="pilihKamar(819, r_819_stat)"
                                >
                                    <div class="text-tengah" :class="text_819 ? text_819 : ''">0819</div>
                                </v-btn>
                            </v-col>                                                             
                        </v-row>
                    </v-col>
                    <v-col xs="2" sm="2" md="2">
                    </v-col>
                    <v-col xs="5" sm="5" md="5">
                        <v-row>
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_802 ? r_802 : ''"
                                    @click="pilihKamar(802, r_802_stat)"
                                >
                                    <div class="text-tengah" :class="text_802 ? text_802 : ''">0802</div>
                                </v-btn>
                            </v-col>    
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_806 ? r_806 : ''"
                                    @click="pilihKamar(806, r_806_stat)"
                                >
                                    <div class="text-tengah" :class="text_806 ? text_806 : ''">0806</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_808 ? r_808 : ''"
                                    @click="pilihKamar(808, r_808_stat)"
                                >
                                    <div class="text-tengah" :class="text_808 ? text_808 : ''">0808</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_810 ? r_810 : ''"
                                    @click="pilihKamar(810, r_810_stat)"
                                >
                                    <div class="text-tengah" :class="text_810 ? text_810 : ''">0810</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_812 ? r_812 : ''"
                                    @click="pilihKamar(812, r_812_stat)"
                                >
                                    <div class="text-tengah" :class="text_812 ? text_812 : ''">0812</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_814 ? r_814 : ''"
                                    @click="pilihKamar(814, r_814_stat)"
                                >
                                    <div class="text-tengah" :class="text_814 ? text_814 : ''">0814</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_816 ? r_816 : ''"
                                    @click="pilihKamar(816, r_816_stat)"
                                >
                                    <div class="text-tengah" :class="text_816 ? text_816 : ''">0816</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_818 ? r_818 : ''"
                                    @click="pilihKamar(818, r_818_stat)"
                                >
                                    <div class="text-tengah" :class="text_818 ? text_818 : ''">0818</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_820 ? r_820 : ''"
                                    @click="pilihKamar(820, r_820_stat)"
                                >
                                    <div class="text-tengah" :class="text_820 ? text_820 : ''">0820</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_822 ? r_822 : ''"
                                    @click="pilihKamar(822, r_822_stat)"
                                >
                                    <div class="text-tengah" :class="text_822 ? text_822 : ''">0822</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_824 ? r_824 : ''"
                                    @click="pilihKamar(824, r_824_stat)"
                                >
                                    <div class="text-tengah" :class="text_824 ? text_824 : ''">0824</div>
                                </v-btn>
                            </v-col>                                                           
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelCleaned')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VDI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_vdi"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelInspect')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_vdi = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VCI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_odn"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelCleaned')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_odn = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('ODI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_odi"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelInspect')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_odi = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('OCI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="loading"
            hide-overlay
            persistent
            width="300"
        >
            <v-card
                color="primary"
                dark
            >
                <v-card-text>
                {{$t('messages.loadingMessage')}}
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>
<script>
    export default {
        data() {
            return {
                selection: 0,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                breadcumbs: [
                    {
                    text: 'AYOLA',
                    disabled: false,
                    href: '/admin/ayala',
                    },
                    {
                    text: 'Dashboard',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Room Status',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                loading: false,
                focus: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                type: 'month',
                typeToLabel: {
                    month: 'Month',
                    week: 'Week',
                    day: 'Day',
                    '4day': '4 Days',
                },
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
                categories: ['Spesialis Konservasi Gigi', 'Spesialis Bedah Mulut'],
                events_name: [
                    {
                        color: "red",
                        end: '2022-09-28',
                        name: "Fuel Book",                   
                        start: '2022-09-28',
                        timed: true
                    },
                ],
                events: [
                    {
                        color: "red",
                        end: '2022-09-28',
                        name: "Fuel Book",                   
                        start: '2022-09-28',
                        timed: true
                    },
                ],
                colors: ['red', 'success'],
                names: ['Fuel Book', 'Available'],
                event: null,
                dialog_day_doctor: false,
                event_days: [],
                date: '',
                start_date: '',
                interval_count: null,
                choiceDate: [],
                display: 'block',
                displayItem: 'none',
                tab: 'tab-1',
                date: '',
                floor: 8,
                modal: false,
                date_from: '',
                r_801: '',
                r_801_stat: '',
                text_801: '',
                r_803: '',
                r_803_stat: '',
                text_803: '',
                r_805: '',
                r_805_stat: '',
                text_805: '',
                r_807: '',
                r_807_stat: '',
                text_807: '',
                r_809: '',
                r_809_stat: '',
                text_809: '',
                r_811: '',
                r_811_stat: '',
                text_811: '',
                r_815: '',
                r_815_stat: '',
                text_815: '',
                r_817: '',
                r_817_stat: '',
                text_817: '',
                r_819: '',
                r_819_stat: '',
                text_819: '',
                r_802: '',
                r_802_stat: '',
                text_802: '',
                r_806: '',
                r_806_stat: '',
                text_806: '',
                r_808: '',
                r_808_stat: '',
                text_808: '',
                r_810: '',
                r_810_stat: '',
                text_810: '',
                r_812: '',
                r_812_stat: '',
                text_812: '',
                r_814: '',
                r_814_stat: '',
                text_814: '',
                r_816: '',
                r_816_stat: '',
                text_816: '',
                r_818: '',
                r_818_stat: '',
                text_818: '',
                r_820: '',
                r_820_stat: '',
                text_820: '',
                r_822: '',
                r_822_stat: '',
                text_822: '',
                r_824: '',
                r_824_stat: '',
                text_824: '',
                r_826: '',
                r_826_stat: '',
                text_826: '',
                ruang: '',
                ruang_value: '',
                countDown: 60,
                dialog: false,
                file: [],
                desc_file:'',
                text_dialog: '',
                dialog_vdi: false,
                dialog_odi: false,
                dialog_odn: false,
                remark: ''
            }
        },
        mounted(){
            this.$store.dispatch('setOverlay', false)
            // this.date = Intl.DateTimeFormat('id', {timeZone: 'Asia/Jakarta', day: 'numeric', month: 'long', weekday: "long", year: "numeric"}).format()
            this.getDataFloor()
        },
        methods:{
            close(){
                this.$router.push('/admin/ayala');
            },
            choiceFloor(floor){
                this.floor = floor
            },
            pilihKamar(value, stat){
                console.log(stat);
                if (stat === 'VDN') {
                    this.dialog = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'VDI'){
                    this.dialog_vdi = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'ODI'){
                    this.dialog_odi = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'ODN'){
                    this.dialog_odn = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                }
            },
            clear(){
                this.remark = ''
                this.ruang = ''
                this.ruang_value = ''
                this.file = []
            },
            async submitStatus(status){
                this.loading = true

                let formData = new FormData()
                formData.append('office_id', 1);
                formData.append('tr_type', status.substr(1, 19));
                formData.append('rm_id', this.ruang_value);
                formData.append('dt_start', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
                formData.append('dt_end', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
                formData.append('remark', this.remark);
                formData.append('status', status);

                for( var i = 0; i < this.file.length; i++ ){
                    formData.append('file[' + i + ']', this.file[i]);
                }

                await axios.post(`${process.env.VUE_APP_URL}/api/ayola/room_hkeeping`, formData ,
                { 
                    headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
                }).then(res => {
                    this.clear()
                    this.loading = false

                    this.getDataFloor()
                    this.dialog = false
                    this.dialog_vdi = false

                    this.snackbar = {
                        color: "success",
                        icon: "mdi-checkbox-marked-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: "Successfully Update Data",
                        visible: true
                    };
                }).catch(err => {
                    this.loading = false
                    this.snackbar = {
                        color: "primary",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Info",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            },
            async getDataFloor(){
                this.$store.dispatch('setOverlay', true)

                await axios.get(`${process.env.VUE_APP_URL}/api/ayola/room/room_status?floor=${this.floor}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    for (let index = 0; index < res.data.data.length; index++) {
                        this.colorPickerCard(res.data.data[index].rm_id, res.data.data[index].stat)
                    }
                    setTimeout(()=>{
                    this.$store.dispatch('setOverlay', false)
                    },500);
                });
            },
            colorPickerCard(rm_id, stat) {
                if (rm_id === '801') {
                    this.r_801_stat = stat
                    if (stat === 'OCI') {
                        this.r_801 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_801 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_801 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_801 = '#9fa1fd'
                        this.text_801 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_801 = '#d22625'
                        this.text_801 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_801 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_801 = '#de1fde'
                        this.text_801 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_801 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_801 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_801 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_801 = '#5f5f5f'
                        this.text_801 = 'text-white'
                    } else{
                        this.r_801 = ''
                    }
                } else if (rm_id === '802') {
                    this.r_802_stat = stat
                    if (stat === 'OCI') {
                        this.r_802 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_802 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_802 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_802 = '#9fa1fd'
                        this.text_802 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_802 = '#d22625'
                        this.text_802 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_802 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_802 = '#de1fde'
                        this.text_802 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_802 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_802 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_802 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_802 = '#5f5f5f'
                        this.text_802 = 'text-white'
                    } else{
                        this.r_802 = ''
                    }
                } else if (rm_id === '803') {
                    this.r_803_stat = stat
                    if (stat === 'OCI') {
                        this.r_803 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_803 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_803 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_803 = '#9fa1fd'
                        this.text_803 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_803 = '#d22625'
                        this.text_803 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_803 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_803 = '#de1fde'
                        this.text_803 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_803 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_803 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_803 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_803 = '#5f5f5f'
                        this.text_803 = 'text-white'
                    } else{
                        this.r_803 = ''
                    }
                } else if (rm_id === '805') {
                    this.r_805_stat = stat
                    if (stat === 'OCI') {
                        this.r_805 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_805 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_805 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_805 = '#9fa1fd'
                        this.text_805 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_805 = '#d22625'
                        this.text_805 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_805 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_805 = '#de1fde'
                        this.text_805 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_805 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_805 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_805 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_805 = '#5f5f5f'
                        this.text_805 = 'text-white'
                    } else{
                        this.r_805 = ''
                    }
                } else if (rm_id === '806') {
                    this.r_806_stat = stat
                    if (stat === 'OCI') {
                        this.r_806 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_806 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_806 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_806 = '#9fa1fd'
                        this.text_806 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_806 = '#d22625'
                        this.text_806 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_806 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_806 = '#de1fde'
                        this.text_806 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_806 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_806 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_806 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_806 = '#5f5f5f'
                        this.text_806 = 'text-white'
                    } else{
                        this.r_806 = ''
                    }
                } else if (rm_id === '807') {
                    this.r_807_stat = stat
                    if (stat === 'OCI') {
                        this.r_807 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_807 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_807 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_807 = '#9fa1fd'
                        this.text_807 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_807 = '#d22625'
                        this.text_807 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_807 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_807 = '#de1fde'
                        this.text_807 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_807 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_807 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_807 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_807 = '#5f5f5f'
                        this.text_807 = 'text-white'
                    } else{
                        this.r_807 = ''
                    }
                } else if (rm_id === '808') {
                    this.r_808_stat = stat
                    if (stat === 'OCI') {
                        this.r_808 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_808 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_808 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_808 = '#9fa1fd'
                        this.text_808 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_808 = '#d22625'
                        this.text_808 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_808 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_808 = '#de1fde'
                        this.text_808 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_808 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_808 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_808 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_808 = '#5f5f5f'
                        this.text_808 = 'text-white'
                    } else{
                        this.r_808 = ''
                    }
                } else if (rm_id === '809') {
                    this.r_809_stat = stat
                    if (stat === 'OCI') {
                        this.r_809 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_809 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_809 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_809 = '#9fa1fd'
                        this.text_809 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_809 = '#d22625'
                        this.text_809 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_809 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_809 = '#de1fde'
                        this.text_809 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_809 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_809 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_809 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_809 = '#5f5f5f'
                        this.text_809 = 'text-white'
                    } else{
                        this.r_809 = ''
                    }
                } else if (rm_id === '810') {
                    this.r_810_stat = stat
                    if (stat === 'OCI') {
                        this.r_810 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_810 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_810 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_810 = '#9fa1fd'
                        this.text_810 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_810 = '#d22625'
                        this.text_810 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_810 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_810 = '#de1fde'
                        this.text_810 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_810 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_810 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_810 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_810 = '#5f5f5f'
                        this.text_810 = 'text-white'
                    } else{
                        this.r_810 = ''
                    }
                } else if (rm_id === '811') {
                    this.r_811_stat = stat
                    if (stat === 'OCI') {
                        this.r_811 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_811 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_811 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_811 = '#9fa1fd'
                        this.text_811 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_811 = '#d22625'
                        this.text_811 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_811 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_811 = '#de1fde'
                        this.text_811 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_811 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_811 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_811 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_811 = '#5f5f5f'
                        this.text_811 = 'text-white'
                    } else{
                        this.r_811 = ''
                    }
                } else if (rm_id === '812') {
                    this.r_812_stat = stat
                    if (stat === 'OCI') {
                        this.r_812 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_812 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_812 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_812 = '#9fa1fd'
                        this.text_812 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_812 = '#d22625'
                        this.text_812 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_812 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_812 = '#de1fde'
                        this.text_812 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_812 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_812 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_812 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_812 = '#5f5f5f'
                        this.text_812 = 'text-white'
                    } else{
                        this.r_812 = ''
                    }
                } else if (rm_id === '814') {
                    this.r_814_stat = stat
                    if (stat === 'OCI') {
                        this.r_814 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_814 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_814 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_814 = '#9fa1fd'
                        this.text_814 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_814 = '#d22625'
                        this.text_814 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_814 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_814 = '#de1fde'
                        this.text_814 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_814 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_814 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_814 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_814 = '#5f5f5f'
                        this.text_814 = 'text-white'
                    } else{
                        this.r_814 = ''
                    }
                } else if (rm_id === '815') {
                    this.r_815_stat = stat
                    if (stat === 'OCI') {
                        this.r_815 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_815 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_815 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_815 = '#9fa1fd'
                        this.text_815 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_815 = '#d22625'
                        this.text_815 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_815 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_815 = '#de1fde'
                        this.text_815 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_815 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_815 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_815 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_815 = '#5f5f5f'
                        this.text_815 = 'text-white'
                    } else{
                        this.r_815 = ''
                    }
                } else if (rm_id === '816') {
                    this.r_816_stat = stat
                    if (stat === 'OCI') {
                        this.r_816 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_816 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_816 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_816 = '#9fa1fd'
                        this.text_816 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_816 = '#d22625'
                        this.text_816 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_816 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_816 = '#de1fde'
                        this.text_816 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_816 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_816 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_816 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_816 = '#5f5f5f'
                        this.text_816 = 'text-white'
                    } else{
                        this.r_816 = ''
                    }
                } else if (rm_id === '817') {
                    this.r_817_stat = stat
                    if (stat === 'OCI') {
                        this.r_817 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_817 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_817 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_817 = '#9fa1fd'
                        this.text_817 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_817 = '#d22625'
                        this.text_817 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_817 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_817 = '#de1fde'
                        this.text_817 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_817 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_817 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_817 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_817 = '#5f5f5f'
                        this.text_817 = 'text-white'
                    } else{
                        this.r_817 = ''
                    }
                } else if (rm_id === '818') {
                    this.r_818_stat = stat
                    if (stat === 'OCI') {
                        this.r_818 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_818 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_818 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_818 = '#9fa1fd'
                        this.text_818 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_818 = '#d22625'
                        this.text_818 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_818 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_818 = '#de1fde'
                        this.text_818 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_818 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_818 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_818 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_818 = '#5f5f5f'
                        this.text_818 = 'text-white'
                    } else{
                        this.r_818 = ''
                    }
                } else if (rm_id === '819') {
                    this.r_819_stat = stat
                    if (stat === 'OCI') {
                        this.r_819 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_819 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_819 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_819 = '#9fa1fd'
                        this.text_819 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_819 = '#d22625'
                        this.text_819 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_819 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_819 = '#de1fde'
                        this.text_819 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_819 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_819 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_819 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_819 = '#5f5f5f'
                        this.text_819 = 'text-white'
                    } else{
                        this.r_819 = ''
                    }
                } else if (rm_id === '820') {
                    this.r_820_stat = stat
                    if (stat === 'OCI') {
                        this.r_820 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_820 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_820 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_820 = '#9fa1fd'
                        this.text_820 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_820 = '#d22625'
                        this.text_820 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_820 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_820 = '#de1fde'
                        this.text_820 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_820 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_820 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_820 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_820 = '#5f5f5f'
                        this.text_820 = 'text-white'
                    } else{
                        this.r_820 = ''
                    }
                } else if (rm_id === '822') {
                    this.r_822_stat = stat
                    if (stat === 'OCI') {
                        this.r_822 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_822 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_822 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_822 = '#9fa1fd'
                        this.text_822 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_822 = '#d22625'
                        this.text_822 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_822 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_822 = '#de1fde'
                        this.text_822 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_822 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_822 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_822 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_822 = '#5f5f5f'
                        this.text_822 = 'text-white'
                    } else{
                        this.r_822 = ''
                    }
                } else if (rm_id === '824') {
                    this.r_824_stat = stat
                    if (stat === 'OCI') {
                        this.r_824 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_824 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_824 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_824 = '#9fa1fd'
                        this.text_824 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_824 = '#d22625'
                        this.text_824 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_824 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_824 = '#de1fde'
                        this.text_824 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_824 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_824 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_824 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_824 = '#5f5f5f'
                        this.text_824 = 'text-white'
                    } else{
                        this.r_824 = ''
                    }
                }
            }
        },
        watch: {
        }
    }
    </script>
    <style scoped>
    .v-slide-group__content {
      justify-content: center;
    }
    </style>