<template>
    <v-card class="rounded-l">
        <v-card-text>
            <v-container fluid>
                <v-row justify="center">
                    <v-col cols="10">
                        <h5 class="text-center text-weight" style="font-weight:600">
                            6 {{$t('labels.floor')}}
                        </h5>
                    </v-col>
                </v-row>
                <v-row class="mt-12 mb-12">
                    <v-col xs="5" sm="5" md="5">
                        <v-row>
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_601 ? r_601 : ''"
                                    @click="pilihKamar(601, r_601_stat)"
                                >
                                    <div class="text-tengah" :class="text_601 ? text_601 : ''">0601</div>
                                </v-btn>
                            </v-col>    
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_603 ? r_603 : ''"
                                    @click="pilihKamar(603, r_603_stat)"
                                >
                                    <div class="text-tengah" :class="text_603 ? text_603 : ''">0603</div>
                                </v-btn>
                            </v-col>  
                            <v-col cols="12" class="hidden-md-and-up">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col cols="12" class="hidden-md-and-up">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col>    
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_605 ? r_605 : ''"
                                    @click="pilihKamar(605, r_605_stat)"
                                >
                                    <div class="text-tengah" :class="text_605 ? text_605 : ''">0605</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_607 ? r_607 : ''"
                                    @click="pilihKamar(607, r_607_stat)"
                                >
                                    <div class="text-tengah" :class="text_607 ? text_607 : ''">0607</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_609 ? r_609 : ''"
                                    @click="pilihKamar(609, r_609_stat)"
                                >
                                    <div class="text-tengah" :class="text_609 ? text_609 : ''">0609</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_611 ? r_611 : ''"
                                    @click="pilihKamar(611, r_611_stat)"
                                >
                                    <div class="text-tengah" :class="text_611 ? text_611 : ''">0611</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_615 ? r_615 : ''"
                                    @click="pilihKamar(615, r_615_stat)"
                                >
                                    <div class="text-tengah" :class="text_615 ? text_615 : ''">0615</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_617 ? r_617 : ''"
                                    @click="pilihKamar(617, r_617_stat)"
                                >
                                    <div class="text-tengah" :class="text_617 ? text_617 : ''">0617</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_619 ? r_619 : ''"
                                    @click="pilihKamar(619, r_619_stat)"
                                >
                                    <div class="text-tengah" :class="text_619 ? text_619 : ''">0619</div>
                                </v-btn>
                            </v-col>                                                             
                        </v-row>
                    </v-col>
                    <v-col xs="2" sm="2" md="2">
                    </v-col>
                    <v-col xs="5" sm="5" md="5">
                        <v-row>
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_602 ? r_602 : ''"
                                    @click="pilihKamar(602, r_602_stat)"
                                >
                                    <div class="text-tengah" :class="text_602 ? text_602 : ''">0602</div>
                                </v-btn>
                            </v-col>    
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_606 ? r_606 : ''"
                                    @click="pilihKamar(606, r_606_stat)"
                                >
                                    <div class="text-tengah" :class="text_606 ? text_606 : ''">0606</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_608 ? r_608 : ''"
                                    @click="pilihKamar(608, r_608_stat)"
                                >
                                    <div class="text-tengah" :class="text_608 ? text_608 : ''">0608</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_610 ? r_610 : ''"
                                    @click="pilihKamar(610, r_610_stat)"
                                >
                                    <div class="text-tengah" :class="text_610 ? text_610 : ''">0610</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_612 ? r_612 : ''"
                                    @click="pilihKamar(612, r_612_stat)"
                                >
                                    <div class="text-tengah" :class="text_612 ? text_612 : ''">0612</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_614 ? r_614 : ''"
                                    @click="pilihKamar(614, r_614_stat)"
                                >
                                    <div class="text-tengah" :class="text_614 ? text_614 : ''">0614</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_616 ? r_616 : ''"
                                    @click="pilihKamar(616, r_616_stat)"
                                >
                                    <div class="text-tengah" :class="text_616 ? text_616 : ''">0616</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_618 ? r_618 : ''"
                                    @click="pilihKamar(618, r_618_stat)"
                                >
                                    <div class="text-tengah" :class="text_618 ? text_618 : ''">0618</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_620 ? r_620 : ''"
                                    @click="pilihKamar(620, r_620_stat)"
                                >
                                    <div class="text-tengah" :class="text_620 ? text_620 : ''">0620</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_622 ? r_622 : ''"
                                    @click="pilihKamar(622, r_622_stat)"
                                >
                                    <div class="text-tengah" :class="text_622 ? text_622 : ''">0622</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_624 ? r_624 : ''"
                                    @click="pilihKamar(624, r_624_stat)"
                                >
                                    <div class="text-tengah" :class="text_624 ? text_624 : ''">0624</div>
                                </v-btn>
                            </v-col>                                                             
                        </v-row>
                    </v-col>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </v-row>
            </v-container>
        </v-card-text>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelCleaned')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VDI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_vdi"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelInspect')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_vdi = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VCI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_odn"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelCleaned')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_odn = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('ODI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_odi"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelInspect')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_odi = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('OCI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="loading"
            hide-overlay
            persistent
            width="300"
        >
            <v-card
                color="primary"
                dark
            >
                <v-card-text>
                {{$t('messages.loadingMessage')}}
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
    export default {
        data() {
            return {
                selection: 0,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                breadcumbs: [
                    {
                    text: 'AYOLA',
                    disabled: false,
                    href: '/admin/ayala',
                    },
                    {
                    text: 'Dashboard',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Room Status',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                loading: false,
                focus: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                type: 'month',
                typeToLabel: {
                    month: 'Month',
                    week: 'Week',
                    day: 'Day',
                    '4day': '4 Days',
                },
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
                categories: ['Spesialis Konservasi Gigi', 'Spesialis Bedah Mulut'],
                events_name: [
                    {
                        color: "red",
                        end: '2022-09-28',
                        name: "Fuel Book",                   
                        start: '2022-09-28',
                        timed: true
                    },
                ],
                events: [
                    {
                        color: "red",
                        end: '2022-09-28',
                        name: "Fuel Book",                   
                        start: '2022-09-28',
                        timed: true
                    },
                ],
                colors: ['red', 'success'],
                names: ['Fuel Book', 'Available'],
                event: null,
                dialog_day_doctor: false,
                event_days: [],
                date: '',
                start_date: '',
                interval_count: null,
                choiceDate: [],
                display: 'block',
                displayItem: 'none',
                tab: 'tab-1',
                date: '',
                floor: 6,
                modal: false,
                date_from: '',
                r_601: '',
                r_601_stat: '',
                text_601: '',
                r_603: '',
                r_603_stat: '',               
                text_603: '',
                r_605: '',
                r_605_stat: '',               
                text_605: '',
                r_607: '',
                r_607_stat: '',
                text_607: '',
                r_609: '',
                r_609_stat: '',
                text_609: '',
                r_611: '',
                r_611_stat: '',
                text_611: '',
                r_615: '',
                r_615_stat: '',
                text_615: '',
                r_617: '',
                r_617_stat: '',
                text_617: '',
                r_619: '',
                r_619_stat: '',
                text_619: '',
                r_602: '',
                r_602_stat: '',
                text_602: '',
                r_606: '',
                r_606_stat: '',
                text_606: '',
                r_608: '',
                r_608_stat: '',
                text_608: '',
                r_610: '',
                r_610_stat: '',
                text_610: '',
                r_612: '',
                r_612_stat: '',
                text_612: '',
                r_614: '',
                r_614_stat: '',
                text_614: '',
                r_616: '',
                r_616_stat: '',
                text_616: '',
                r_618: '',
                r_618_stat: '',
                text_618: '',
                r_620: '',
                r_620_stat: '',
                text_620: '',
                r_622: '',
                r_622_stat: '',
                text_622: '',
                r_624: '',
                r_624_stat: '',
                text_624: '',
                r_626: '',
                r_626_stat: '',
                text_626: '',
                ruang: '',
                ruang_value: '',
                countDown: 60,
                dialog: false,
                file: [],
                desc_file:'',
                text_dialog: '',
                dialog_vdi: false,
                dialog_odi: false,
                dialog_odn: false,
                remark: ''
            }
        },
        mounted(){
            this.$store.dispatch('setOverlay', false)
            // this.date = Intl.DateTimeFormat('id', {timeZone: 'Asia/Jakarta', day: 'numeric', month: 'long', weekday: "long", year: "numeric"}).format()
            this.getDataFloor()
        },
        methods:{
            close(){
                this.$router.push('/admin/ayala');
            },
            choiceFloor(floor){
                this.floor = floor
            },
            pilihKamar(value, stat){
                console.log(stat);
                if (stat === 'VDN') {
                    this.dialog = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'VDI'){
                    this.dialog_vdi = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'ODI'){
                    this.dialog_odi = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'ODN'){
                    this.dialog_odn = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                }
            },
            clear(){
                this.remark = ''
                this.ruang = ''
                this.ruang_value = ''
                this.file = []
            },
            async submitStatus(status){
                this.loading = true

                let formData = new FormData()
                formData.append('office_id', 1);
                formData.append('tr_type', status.substr(1, 19));
                formData.append('rm_id', this.ruang_value);
                formData.append('dt_start', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
                formData.append('dt_end', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
                formData.append('remark', this.remark);
                formData.append('status', status);

                for( var i = 0; i < this.file.length; i++ ){
                    formData.append('file[' + i + ']', this.file[i]);
                }

                await axios.post(`${process.env.VUE_APP_URL}/api/ayola/room_hkeeping`, formData ,
                { 
                    headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
                }).then(res => {
                    this.clear()
                    this.loading = false

                    this.getDataFloor()
                    this.dialog = false
                    this.dialog_vdi = false

                    this.snackbar = {
                        color: "success",
                        icon: "mdi-checkbox-marked-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: "Successfully Update Data",
                        visible: true
                    };
                }).catch(err => {
                    this.loading = false
                    this.snackbar = {
                        color: "primary",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Info",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            },
            async getDataFloor(){
                this.$store.dispatch('setOverlay', true)

                await axios.get(`${process.env.VUE_APP_URL}/api/ayola/room/room_status?floor=${this.floor}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    for (let index = 0; index < res.data.data.length; index++) {
                        this.colorPickerCard(res.data.data[index].rm_id, res.data.data[index].stat)
                    }

                    setTimeout(()=>{
                    this.$store.dispatch('setOverlay', false)
                    },500);
                });
            },
            colorPickerCard(rm_id, stat) {
                if (rm_id === '601') {
                    this.r_601_stat = stat
                    if (stat === 'OCI') {
                        this.r_601 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_601 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_601 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_601 = '#9fa1fd'
                        this.text_601 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_601 = '#d22625'
                        this.text_601 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_601 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_601 = '#de1fde'
                        this.text_601 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_601 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_601 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_601 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_601 = '#5f5f5f'
                        this.text_601 = 'text-white'
                    } else{
                        this.r_601 = ''
                    }
                } else if (rm_id === '602') {
                    this.r_602_stat = stat

                    if (stat === 'OCI') {
                        this.r_602 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_602 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_602 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_602 = '#9fa1fd'
                        this.text_602 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_602 = '#d22625'
                        this.text_602 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_602 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_602 = '#de1fde'
                        this.text_602 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_602 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_602 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_602 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_602 = '#5f5f5f'
                        this.text_602 = 'text-white'
                    } else{
                        this.r_602 = ''
                    }
                } else if (rm_id === '603') {
                    this.r_603_stat = stat

                    if (stat === 'OCI') {
                        this.r_603 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_603 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_603 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_603 = '#9fa1fd'
                        this.text_603 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_603 = '#d22625'
                        this.text_603 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_603 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_603 = '#de1fde'
                        this.text_603 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_603 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_603 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_603 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_603 = '#5f5f5f'
                        this.text_603 = 'text-white'
                    } else{
                        this.r_603 = ''
                    }
                } else if (rm_id === '605') {
                    this.r_605_stat = stat

                    if (stat === 'OCI') {
                        this.r_605 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_605 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_605 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_605 = '#9fa1fd'
                        this.text_605 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_605 = '#d22625'
                        this.text_605 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_605 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_605 = '#de1fde'
                        this.text_605 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_605 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_605 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_605 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_605 = '#5f5f5f'
                        this.text_605 = 'text-white'
                    } else{
                        this.r_605 = ''
                    }
                } else if (rm_id === '606') {
                    this.r_606_stat = stat

                    if (stat === 'OCI') {
                        this.r_606 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_606 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_606 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_606 = '#9fa1fd'
                        this.text_606 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_606 = '#d22625'
                        this.text_606 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_606 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_606 = '#de1fde'
                        this.text_606 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_606 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_606 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_606 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_606 = '#5f5f5f'
                        this.text_606 = 'text-white'
                    } else{
                        this.r_606 = ''
                    }
                } else if (rm_id === '607') {
                    this.r_607_stat = stat

                    if (stat === 'OCI') {
                        this.r_607 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_607 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_607 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_607 = '#9fa1fd'
                        this.text_607 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_607 = '#d22625'
                        this.text_607 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_607 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_607 = '#de1fde'
                        this.text_607 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_607 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_607 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_607 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_607 = '#5f5f5f'
                        this.text_607 = 'text-white'
                    } else{
                        this.r_607 = ''
                    }
                } else if (rm_id === '608') {
                    this.r_608_stat = stat

                    if (stat === 'OCI') {
                        this.r_608 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_608 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_608 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_608 = '#9fa1fd'
                        this.text_608 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_608 = '#d22625'
                        this.text_608 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_608 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_608 = '#de1fde'
                        this.text_608 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_608 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_608 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_608 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_608 = '#5f5f5f'
                        this.text_608 = 'text-white'
                    } else{
                        this.r_608 = ''
                    }
                } else if (rm_id === '609') {
                    this.r_609_stat = stat

                    if (stat === 'OCI') {
                        this.r_609 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_609 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_609 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_609 = '#9fa1fd'
                        this.text_609 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_609 = '#d22625'
                        this.text_609 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_609 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_609 = '#de1fde'
                        this.text_609 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_609 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_609 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_609 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_609 = '#5f5f5f'
                        this.text_609 = 'text-white'
                    } else{
                        this.r_609 = ''
                    }
                } else if (rm_id === '610') {
                    this.r_610_stat = stat

                    if (stat === 'OCI') {
                        this.r_610 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_610 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_610 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_610 = '#9fa1fd'
                        this.text_610 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_610 = '#d22625'
                        this.text_610 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_610 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_610 = '#de1fde'
                        this.text_610 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_610 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_610 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_610 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_610 = '#5f5f5f'
                        this.text_610 = 'text-white'
                    } else{
                        this.r_610 = ''
                    }
                } else if (rm_id === '611') {
                    this.r_611_stat = stat

                    if (stat === 'OCI') {
                        this.r_611 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_611 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_611 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_611 = '#9fa1fd'
                        this.text_611 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_611 = '#d22625'
                        this.text_611 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_611 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_611 = '#de1fde'
                        this.text_611 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_611 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_611 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_611 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_611 = '#5f5f5f'
                        this.text_611 = 'text-white'
                    } else{
                        this.r_611 = ''
                    }
                } else if (rm_id === '612') {
                    this.r_612_stat = stat

                    if (stat === 'OCI') {
                        this.r_612 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_612 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_612 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_612 = '#9fa1fd'
                        this.text_612 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_612 = '#d22625'
                        this.text_612 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_612 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_612 = '#de1fde'
                        this.text_612 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_612 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_612 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_612 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_612 = '#5f5f5f'
                        this.text_612 = 'text-white'
                    } else{
                        this.r_612 = ''
                    }
                } else if (rm_id === '614') {
                    this.r_614_stat = stat

                    if (stat === 'OCI') {
                        this.r_614 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_614 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_614 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_614 = '#9fa1fd'
                        this.text_614 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_614 = '#d22625'
                        this.text_614 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_614 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_614 = '#de1fde'
                        this.text_614 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_614 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_614 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_614 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_614 = '#5f5f5f'
                        this.text_614 = 'text-white'
                    } else{
                        this.r_614 = ''
                    }
                } else if (rm_id === '615') {
                    this.r_615_stat = stat
                    if (stat === 'OCI') {
                        this.r_615 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_615 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_615 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_615 = '#9fa1fd'
                        this.text_615 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_615 = '#d22625'
                        this.text_615 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_615 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_615 = '#de1fde'
                        this.text_615 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_615 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_615 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_615 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_615 = '#5f5f5f'
                        this.text_615 = 'text-white'
                    } else{
                        this.r_615 = ''
                    }
                } else if (rm_id === '616') {
                    this.r_616_stat = stat
                    if (stat === 'OCI') {
                        this.r_616 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_616 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_616 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_616 = '#9fa1fd'
                        this.text_616 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_616 = '#d22625'
                        this.text_616 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_616 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_616 = '#de1fde'
                        this.text_616 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_616 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_616 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_616 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_616 = '#5f5f5f'
                        this.text_616 = 'text-white'
                    } else{
                        this.r_616 = ''
                    }
                } else if (rm_id === '617') {
                    this.r_617_stat = stat
                    if (stat === 'OCI') {
                        this.r_617 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_617 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_617 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_617 = '#9fa1fd'
                        this.text_617 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_617 = '#d22625'
                        this.text_617 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_617 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_617 = '#de1fde'
                        this.text_617 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_617 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_617 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_617 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_617 = '#5f5f5f'
                        this.text_617 = 'text-white'
                    } else{
                        this.r_617 = ''
                    }
                } else if (rm_id === '618') {
                    this.r_618_stat = stat
                    if (stat === 'OCI') {
                        this.r_618 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_618 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_618 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_618 = '#9fa1fd'
                        this.text_618 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_618 = '#d22625'
                        this.text_618 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_618 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_618 = '#de1fde'
                        this.text_618 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_618 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_618 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_618 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_618 = '#5f5f5f'
                        this.text_618 = 'text-white'
                    } else{
                        this.r_618 = ''
                    }
                } else if (rm_id === '619') {
                    this.r_619_stat = stat
                    if (stat === 'OCI') {
                        this.r_619 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_619 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_619 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_619 = '#9fa1fd'
                        this.text_619 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_619 = '#d22625'
                        this.text_619 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_619 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_619 = '#de1fde'
                        this.text_619 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_619 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_619 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_619 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_619 = '#5f5f5f'
                        this.text_619 = 'text-white'
                    } else{
                        this.r_619 = ''
                    }
                } else if (rm_id === '620') {
                    this.r_620_stat = stat
                    if (stat === 'OCI') {
                        this.r_620 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_620 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_620 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_620 = '#9fa1fd'
                        this.text_620 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_620 = '#d22625'
                        this.text_620 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_620 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_620 = '#de1fde'
                        this.text_620 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_620 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_620 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_620 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_620 = '#5f5f5f'
                        this.text_620 = 'text-white'
                    } else{
                        this.r_620 = ''
                    }
                } else if (rm_id === '622') {
                    this.r_622_stat = stat
                    if (stat === 'OCI') {
                        this.r_622 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_622 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_622 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_622 = '#9fa1fd'
                        this.text_622 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_622 = '#d22625'
                        this.text_622 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_622 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_622 = '#de1fde'
                        this.text_622 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_622 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_622 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_622 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_622 = '#5f5f5f'
                        this.text_622 = 'text-white'
                    } else{
                        this.r_622 = ''
                    }
                } else if (rm_id === '624') {
                    this.r_624_stat = stat
                    if (stat === 'OCI') {
                        this.r_624 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_624 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_624 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_624 = '#9fa1fd'
                        this.text_624 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_624 = '#d22625'
                        this.text_624 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_624 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_624 = '#de1fde'
                        this.text_624 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_624 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_624 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_624 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_624 = '#5f5f5f'
                        this.text_624 = 'text-white'
                    } else{
                        this.r_624 = ''
                    }
                }
            }
        },
        watch: {
        }
    }
    </script>
    <style scoped>
    .v-slide-group__content {
      justify-content: center;
    }
    </style>