<template>
    <v-container fluid style="margin-bottom:100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="elevation-12 rounded-l" style="border-top: 3px solid #1565C0;">
                    <v-card-title >    
                        {{$t('labels.roomStatus')}}             
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            small
                            color="#e83e8c;"
                            @click="close()"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" class="blue-text p-3">
                                    <h6 style="font-weight:600"> {{date}}</h6>
                                </v-col>
                                <v-col cols="12">
                                    <v-slide-group
                                        show-arrows
                                        v-touch="{
                                        down: () => ''
                                        }"
                                    >
                                        <v-slide-item
                                            v-for="n in 6"
                                            :key="n"
                                            v-slot="{ active }"
                                            class="mr-1 ml-1"
                                        >
                                            <v-chip :input-value="active" class="pt-7 pb-5" @click="choiceFloor(n + 4)" :class="floor == n + 4 ? 'deep-purple text-white' : ''">
                                                <h4 class="mr-2">{{n + 4}}</h4> {{$t('labels.floor')}}
                                            </v-chip>
                                        </v-slide-item>
                                    </v-slide-group>
                                </v-col>
                                <v-divider></v-divider>
                                <v-col cols="12" v-if="floor === 5">
                                    <v-card class="rounded-xl">
                                        <v-card-text>
                                            <v-container fluid>
                                                <v-row justify="center">
                                                    <v-col cols="10">
                                                        <h5 class="text-center text-weight" style="font-weight:600">
                                                           5 {{$t('labels.floor')}}
                                                        </h5>
                                                    </v-col>
                                                </v-row>

                                                <v-row class="mt-12 mb-12">
                                                    <v-col xs="5" sm="5" md="5">
                                                        <v-row>
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_501 ? r_501 : ''"
                                                                    @click="pilihKamar(501, r_501_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_501 ? text_501 : ''">0501</div>
                                                                </v-btn>
                                                            </v-col>    
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_503 ? r_503 : ''"
                                                                    @click="pilihKamar(503, r_503_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_503 ? text_503 : ''">0503</div>
                                                                </v-btn>
                                                            </v-col>  
                                                            <v-col cols="12" class="hidden-md-and-up">
                                                                <v-btn
                                                                    block
                                                                    disabled
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                >
                                                                </v-btn>
                                                            </v-col> 
                                                            <v-col cols="12" class="hidden-md-and-up">
                                                                <v-btn
                                                                    block
                                                                    disabled
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                >
                                                                </v-btn>
                                                            </v-col> 
                                                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                                                <v-btn
                                                                    block
                                                                    disabled
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                >
                                                                </v-btn>
                                                            </v-col> 
                                                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                                                <v-btn
                                                                    block
                                                                    disabled
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                >
                                                                </v-btn>
                                                            </v-col>   
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_505 ? r_505 : ''"
                                                                    @click="pilihKamar(505, r_505_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_505 ? text_505 : ''">0505</div>
                                                                </v-btn>
                                                            </v-col>   
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_507 ? r_507 : ''"
                                                                    @click="pilihKamar(507, r_507_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_507 ? text_507 : ''">0507</div>
                                                                </v-btn>
                                                            </v-col>  
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_509 ? r_509 : ''"
                                                                    @click="pilihKamar(509, r_509_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_509 ? text_509 : ''">0509</div>
                                                                </v-btn>
                                                            </v-col>   
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_511 ? r_511 : ''"
                                                                    @click="pilihKamar(511, r_511_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_511 ? text_511 : ''">0511</div>
                                                                </v-btn>
                                                            </v-col> 
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_515 ? r_515 : ''"
                                                                    @click="pilihKamar(515, r_515_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_515 ? text_515 : ''">0515</div>
                                                                </v-btn>
                                                            </v-col> 
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_517 ? r_517 : ''"
                                                                    @click="pilihKamar(517, r_517_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_517 ? text_517 : ''">0517</div>
                                                                </v-btn>
                                                            </v-col> 
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_519 ? r_519 : ''"
                                                                    @click="pilihKamar(519, r_519_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_519 ? text_519 : ''">0519</div>
                                                                </v-btn>
                                                            </v-col>                                                             
                                                        </v-row>
                                                    </v-col>
                                                    <v-col xs="2" sm="2" md="2">
                                                    </v-col>
                                                    <v-col xs="5" sm="5" md="5">
                                                        <v-row>
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_502 ? r_502 : ''"
                                                                    @click="pilihKamar(502, r_502_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_502 ? text_502 : ''">0502</div>
                                                                </v-btn>
                                                            </v-col>    
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_506 ? r_506 : ''"
                                                                    @click="pilihKamar(506, r_506_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_506 ? text_506 : ''">0506</div>
                                                                </v-btn>
                                                            </v-col>  
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_508 ? r_508 : ''"
                                                                    @click="pilihKamar(508, r_508_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_508 ? text_508 : ''">0508</div>
                                                                </v-btn>
                                                            </v-col> 
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_510 ? r_510 : ''"
                                                                    @click="pilihKamar(510, r_510_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_510 ? text_510 : ''">0510</div>
                                                                </v-btn>
                                                            </v-col>   
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_512 ? r_512 : ''"
                                                                    @click="pilihKamar(512, r_512_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_512 ? text_512 : ''">0512</div>
                                                                </v-btn>
                                                            </v-col>   
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_514 ? r_514 : ''"
                                                                    @click="pilihKamar(514, r_514_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_514 ? text_514 : ''">0514</div>
                                                                </v-btn>
                                                            </v-col>  
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_516 ? r_516 : ''"
                                                                    @click="pilihKamar(516, r_516_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_516 ? text_516 : ''">0516</div>
                                                                </v-btn>
                                                            </v-col>   
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_518 ? r_518 : ''"
                                                                    @click="pilihKamar(518, r_518_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_518 ? text_518 : ''">0518</div>
                                                                </v-btn>
                                                            </v-col> 
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_520 ? r_520 : ''"
                                                                    @click="pilihKamar(520, r_520_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_520 ? text_520 : ''">0520</div>
                                                                </v-btn>
                                                            </v-col> 
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_522 ? r_522 : ''"
                                                                    @click="pilihKamar(522, r_522_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_522 ? text_522 : ''">0522</div>
                                                                </v-btn>
                                                            </v-col> 
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_524 ? r_524 : ''"
                                                                    @click="pilihKamar(524, r_524_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_524 ? text_524 : ''">0524</div>
                                                                </v-btn>
                                                            </v-col>     
                                                            <v-col xs="6" sm="6" md="6">
                                                                <v-btn
                                                                    block
                                                                    elevation="2"
                                                                    x-large
                                                                    rounded
                                                                    center
                                                                    :color="r_526 ? r_526 : ''"
                                                                    @click="pilihKamar(526, r_526_stat)"
                                                                >
                                                                    <div class="text-tengah" :class="text_526 ? text_526 : ''">0526</div>
                                                                </v-btn>
                                                            </v-col>                                                             
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" v-if="floor === 6">
                                    <room-status-floor-six></room-status-floor-six>
                                </v-col>
                                <v-col cols="12" v-if="floor === 7">
                                    <room-status-floor-seven></room-status-floor-seven>
                                </v-col>
                                <v-col cols="12" v-if="floor === 8">
                                    <room-status-floor-eight></room-status-floor-eight>
                                </v-col>
                                <v-col cols="12" v-if="floor === 9">
                                    <room-status-floor-nine></room-status-floor-nine>
                                </v-col>
                                <v-col cols="12" v-if="floor === 10">
                                    <room-status-floor-ten></room-status-floor-ten>
                                </v-col>
                            </v-row>
                            <v-row class="mt-12">
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#999999" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        Not Available                                                            
                                    </div>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#ffe2e5" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        OCI
                                    </div>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#a2ff9f" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        VCI
                                    </div>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#e6e691" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        VDI
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#9fa1fd" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        OCN                                                         
                                    </div>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#d22625" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        ODI ODN                                                       
                                    </div>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#aafaf9" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        VCI / VCN Block                                                     
                                    </div>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#de1fde" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        Discrepency
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#ffdf9e" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        VCN                                                      
                                    </div>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#e0e2e1" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        Due Out                                                       
                                    </div>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#ffffa1" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        VDN                                                    
                                    </div>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <div class="d-flex">
                                        <v-chip color="#5f5f5f" class="pl-2 pr-2 pt-0 pb-0 mr-2 mt-1" style="height:25px;width: 60px;"></v-chip>
                                        Maintenance
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"
                >
                    <v-card class="p-3 rounded-xl">
                        <v-card-title class="text-center">
                            <v-row align="center" justify="center">
                                <v-col cols="12">
                                    <h4>
                                        {{$t('labels.labelCleaned')}} {{ruang}}?
                                    </h4>
                                </v-col>
                            </v-row>  
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid class="mt-5">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                        <v-icon x-large color="primary" class="text-center">
                                            mdi-check-bold
                                        </v-icon>
                                    </v-col>
                                </v-row> 
                                <v-row class="mt-5 mb-2">
                                    <v-col 
                                        cols="12"
                                    >
                                        <v-file-input
                                            solo
                                            v-model="file"
                                            :label="$t('labels.pictureFile')"
                                            small-chips
                                            counter
                                            multiple
                                            append-icon="mdi-image"
                                            class="border-12"
                                            accept="image/*"
                                            height="80"
                                            hide-details=true
                                        ></v-file-input>
                                    </v-col>
                                    <v-col
                                        cols="12" class="mt-3"
                                    >
                                        <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                        <v-textarea
                                            outlined
                                            name="input-7-4"
                                            :label="$t('labels.note')"
                                            v-model="remark"
                                            class="mt-2 border-12"
                                            hide-details=true
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" xs="12" sm="3" md="4">
                                        <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog = false">{{$t('buttons.cancel')}}</v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="3" md="4">
                                        <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VDI')">{{$t('buttons.save')}}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialog_vdi"
                    persistent
                    max-width="600px"
                >
                    <v-card class="p-3 rounded-xl">
                        <v-card-title class="text-center">
                            <v-row align="center" justify="center">
                                <v-col cols="12">
                                    <h4>
                                        {{$t('labels.labelInspect')}} {{ruang}}?
                                    </h4>
                                </v-col>
                            </v-row>  
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid class="mt-5">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                        <v-icon x-large color="primary" class="text-center">
                                            mdi-check-bold
                                        </v-icon>
                                    </v-col>
                                </v-row> 
                                <v-row class="mt-5 mb-2">
                                    <v-col 
                                        cols="12"
                                    >
                                        <v-file-input
                                            solo
                                            v-model="file"
                                            :label="$t('labels.pictureFile')"
                                            small-chips
                                            counter
                                            multiple
                                            append-icon="mdi-image"
                                            class="border-12"
                                            accept="image/*"
                                            height="80"
                                            hide-details=true
                                        ></v-file-input>
                                    </v-col>
                                    <v-col
                                        cols="12" class="mt-3"
                                    >
                                        <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                        <v-textarea
                                            outlined
                                            name="input-7-4"
                                            :label="$t('labels.note')"
                                            v-model="remark"
                                            class="mt-2 border-12"
                                            hide-details=true
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" xs="12" sm="3" md="4">
                                        <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_vdi = false">{{$t('buttons.cancel')}}</v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="3" md="4">
                                        <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VCI')">{{$t('buttons.save')}}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog
                    v-model="dialogODI"
                    persistent
                    max-width="600px"
                >
                    <v-card class="p-3 rounded-xl">
                        <v-card-title class="text-center">
                            <v-row align="center" justify="center">
                                <v-col cols="12">
                                    <h4>
                                        {{$t('labels.labelInspect')}} {{ruang}}?
                                    </h4>
                                </v-col>
                            </v-row>  
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid class="mt-5">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                        <v-icon x-large color="primary" class="text-center">
                                            mdi-check-bold
                                        </v-icon>
                                    </v-col>
                                </v-row> 
                                <v-row class="mt-5 mb-2">
                                    <v-col 
                                        cols="12"
                                    >
                                        <v-file-input
                                            solo
                                            v-model="file"
                                            :label="$t('labels.pictureFile')"
                                            small-chips
                                            counter
                                            multiple
                                            append-icon="mdi-image"
                                            class="border-12"
                                            accept="image/*"
                                            height="80"
                                            hide-details=true
                                        ></v-file-input>
                                    </v-col>
                                    <v-col
                                        cols="12" class="mt-3"
                                    >
                                        <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                        <v-textarea
                                            outlined
                                            name="input-7-4"
                                            :label="$t('labels.note')"
                                            v-model="remark"
                                            class="mt-2 border-12"
                                            hide-details=true
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" xs="12" sm="3" md="4">
                                        <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialogODI = false">{{$t('buttons.cancel')}}</v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="3" md="4">
                                        <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('OCI')">{{$t('buttons.save')}}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog
                    v-model="dialogODN"
                    persistent
                    max-width="600px"
                >
                    <v-card class="p-3 rounded-xl">
                        <v-card-title class="text-center">
                            <v-row align="center" justify="center">
                                <v-col cols="12">
                                    <h4>
                                        {{$t('labels.labelCleaned')}} {{ruang}}?
                                    </h4>
                                </v-col>
                            </v-row>  
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid class="mt-5">
                                <v-row align="center" justify="center">
                                    <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                        <v-icon x-large color="primary" class="text-center">
                                            mdi-check-bold
                                        </v-icon>
                                    </v-col>
                                </v-row> 
                                <v-row class="mt-5 mb-2">
                                    <v-col 
                                        cols="12"
                                    >
                                        <v-file-input
                                            solo
                                            v-model="file"
                                            :label="$t('labels.pictureFile')"
                                            small-chips
                                            counter
                                            multiple
                                            append-icon="mdi-image"
                                            class="border-12"
                                            accept="image/*"
                                            height="80"
                                            hide-details=true
                                        ></v-file-input>
                                    </v-col>
                                    <v-col
                                        cols="12" class="mt-3"
                                    >
                                        <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                        <v-textarea
                                            outlined
                                            name="input-7-4"
                                            :label="$t('labels.note')"
                                            v-model="remark"
                                            class="mt-2 border-12"
                                            hide-details=true
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" xs="12" sm="3" md="4">
                                        <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialogODN = false">{{$t('buttons.cancel')}}</v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="3" md="4">
                                        <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('ODI')">{{$t('buttons.save')}}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog
                    v-model="loading"
                    hide-overlay
                    persistent
                    width="300"
                >
                    <v-card
                        color="primary"
                        dark
                    >
                        <v-card-text>
                        {{$t('messages.loadingMessage')}}
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        ></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
        </v-row>
    </v-container>
</template>
<script>
import RoomStatusFloorSix from './RoomStatusFloorSix.vue'
import RoomStatusFloorSeven from './RoomStatusFloorSeven.vue'
import RoomStatusFloorEight from './RoomStatusFloorEight.vue'
import RoomStatusFloorNine from './RoomStatusFloorNine.vue'
import RoomStatusFloorTen from './RoomStatusFloorTen.vue'

export default {
    components:{
        RoomStatusFloorSix, RoomStatusFloorSeven, RoomStatusFloorEight, RoomStatusFloorNine, RoomStatusFloorTen
    },
    data() {
        return {
            selection: 0,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'AYOLA',
                disabled: false,
                href: '/admin/ayala',
                },
                {
                text: 'Dashboard',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: this.$t('labels.roomStatus'),
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            focus: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            categories: ['Spesialis Konservasi Gigi', 'Spesialis Bedah Mulut'],
            events_name: [
                {
                    color: "red",
                    end: '2022-09-28',
                    name: "Fuel Book",                   
                    start: '2022-09-28',
                    timed: true
                },
            ],
            events: [
                {
                    color: "red",
                    end: '2022-09-28',
                    name: "Fuel Book",                   
                    start: '2022-09-28',
                    timed: true
                },
            ],
            colors: ['red', 'success'],
            names: ['Fuel Book', 'Available'],
            event: null,
            dialog_day_doctor: false,
            event_days: [],
            date: '',
            start_date: '',
            interval_count: null,
            choiceDate: [],
            display: 'block',
            displayItem: 'none',
            tab: 'tab-1',
            date: '',
            floor: 5,
            modal: false,
            date_from: '',
            r_501: '',
            r_501_stat: '',
            text_501: '',
            r_503: '',
            r_503_stat: '',
            text_503: '',
            r_505: '',
            r_505_stat: '',
            text_505: '',
            r_507: '',
            r_507_stat: '',
            text_507: '',
            r_509: '',
            r_509_stat: '',
            text_509: '',
            r_511: '',
            r_511_stat: '',
            text_511: '',
            r_515: '',
            r_515_stat: '',
            text_515: '',
            r_517: '',
            r_517_stat: '',
            text_517: '',
            r_519: '',
            r_519_stat: '',
            text_519: '',
            r_502: '',
            r_502_stat: '',
            text_502: '',
            r_506: '',
            r_506_stat: '',
            text_506: '',
            r_508: '',
            r_508_stat: '',
            text_508: '',
            r_510: '',
            r_510_stat: '',
            text_510: '',
            r_512: '',
            r_512_stat: '',
            text_512: '',
            r_514: '',
            r_514_stat: '',
            text_514: '',
            r_516: '',
            r_516_stat: '',
            text_516: '',
            r_518: '',
            r_518_stat: '',
            text_518: '',
            r_520: '',
            r_520_stat: '',
            text_520: '',
            r_522: '',
            r_522_stat: '',
            text_522: '',
            r_524: '',
            r_524_stat: '',
            text_524: '',
            r_526: '',
            r_526_stat: '',
            text_526: '',
            ruang: '',
            ruang_value: '',
            countDown: 60,
            dialog: false,
            file: [],
            desc_file:'',
            text_dialog: '',
            dialog_vdi: false,
            dialogODN:false,
            dialogODI: false,
            
            remark: ''
        }
    },
    mounted(){
        this.$store.dispatch('setOverlay', false)
        this.date = Intl.DateTimeFormat('id', {timeZone: 'Asia/Jakarta', day: 'numeric', month: 'long', weekday: "long", year: "numeric"}).format()
        this.getDataFloor()
    },
    methods:{
        countDownTimer () {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            } else{
                this.dialog = false
            }
        },
        close(){
            this.$router.push('/admin/ayala');
        },
        choiceFloor(floor){
            this.floor = floor
        },
        pilihKamar(value, stat){
            console.log("pilihKamar = " + value);
            console.log(stat);
            if (stat === 'VDN') {
                this.dialog = true
                this.ruang_value = value
                this.ruang = '0' + value
            } else if(stat === 'VDI'){
                this.dialog_vdi = true
                this.ruang_value = value
                this.ruang = '0' + value
            } else if(stat === 'ODI') {
                // bikin form untuk ODI
                this.dialogODI = true
                this.ruang_value = value
                this.ruang = '0' + value
            } else if(stat === 'ODN') {
                // bikin form untuk ODN
                this.dialogODN = true
                this.ruang_value = value
                this.ruang = '0' + value
            }
        },
        clear(){
            this.remark = ''
            this.ruang = ''
            this.ruang_value = ''
            this.file = []
        },
        async submitStatus(status){
            this.loading = true
            console.log(this.$store.state.user.shift)
            let formData = new FormData()
            formData.append('office_id', 1);
            formData.append('tr_type', status.substr(1, 19));
            formData.append('rm_id', this.ruang_value);
            formData.append('dt_start', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
            formData.append('dt_end', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
            formData.append('remark', this.remark);
            formData.append('status', status);
            formData.append('shift_id', this.$store.state.user.shift);
            formData.append('outlet_id', this.$store.state.outlet.hkeep);


            for( var i = 0; i < this.file.length; i++ ){
                formData.append('file[' + i + ']', this.file[i]);
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/ayola/room_hkeeping`, formData ,
            { 
                headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
            }).then(res => {
                this.clear()
                this.loading = false

                this.getDataFloor()
                this.dialog = false
                this.dialog_vdi = false
                this.dialogODI =false
                this.dialogODN = false

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Update Data",
                    visible: true
                };
            }).catch(err => {
                this.snackbar = {
                    color: "primary",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Info",
                    text: err.response.data.message,
                    visible: true
                };
                this.loading = false
            })
        },
        async getDataFloor(){
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/ayola/room/room_status?floor=${this.floor}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                for (let index = 0; index < res.data.data.length; index++) {
                    this.colorPickerCard(res.data.data[index].rm_id, res.data.data[index].stat)
                }

                setTimeout(()=>{
                  this.$store.dispatch('setOverlay', false)
                },500);

            });
        },
        colorPickerCard(rm_id, stat) {
            if (rm_id === '501') {
                this.r_501_stat = stat
                if (stat === 'OCI') {
                    this.r_501 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_501 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_501 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_501 = '#9fa1fd'
                    this.text_501 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_501 = '#d22625'
                    this.text_501 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_501 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_501 = '#de1fde'
                    this.text_501 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_501 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_501 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_501 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_501 = '#5f5f5f'
                    this.text_501 = 'text-white'
                } else{
                    this.r_501 = ''
                }
            } else if (rm_id === '502') {
                this.r_502_stat = stat
                if (stat === 'OCI') {
                    this.r_502 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_502 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_502 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_502 = '#9fa1fd'
                    this.text_502 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_502 = '#d22625'
                    this.text_502 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_502 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_502 = '#de1fde'
                    this.text_502 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_502 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_502 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_502 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_502 = '#5f5f5f'
                    this.text_502 = 'text-white'
                } else{
                    this.r_502 = ''
                }
            } else if (rm_id === '503') {
                this.r_503_stat = stat

                if (stat === 'OCI') {
                    this.r_503 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_503 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_503 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_503 = '#9fa1fd'
                    this.text_503 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_503 = '#d22625'
                    this.text_503 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_503 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_503 = '#de1fde'
                    this.text_503 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_503 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_503 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_503 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_503 = '#5f5f5f'
                    this.text_503 = 'text-white'
                } else{
                    this.r_503 = ''
                }
            } else if (rm_id === '505') {
                this.r_505_stat = stat

                if (stat === 'OCI') {
                    this.r_505 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_505 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_505 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_505 = '#9fa1fd'
                    this.text_505 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_505 = '#d22625'
                    this.text_505 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_505 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_505 = '#de1fde'
                    this.text_505 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_505 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_505 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_505 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_505 = '#5f5f5f'
                    this.text_505 = 'text-white'
                } else{
                    this.r_505 = ''
                }
            } else if (rm_id === '506') {
                this.r_506_stat = stat

                if (stat === 'OCI') {
                    this.r_506 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_506 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_506 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_506 = '#9fa1fd'
                    this.text_506 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_506 = '#d22625'
                    this.text_506 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_506 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_506 = '#de1fde'
                    this.text_506 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_506 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_506 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_506 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_506 = '#5f5f5f'
                    this.text_506 = 'text-white'
                } else{
                    this.r_506 = ''
                }
            } else if (rm_id === '507') {
                this.r_507_stat = stat

                if (stat === 'OCI') {
                    this.r_507 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_507 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_507 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_507 = '#9fa1fd'
                    this.text_507 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_507 = '#d22625'
                    this.text_507 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_507 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_507 = '#de1fde'
                    this.text_507 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_507 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_507 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_507 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_507 = '#5f5f5f'
                    this.text_507 = 'text-white'
                } else{
                    this.r_507 = ''
                }
            } else if (rm_id === '508') {
                this.r_508_stat = stat

                if (stat === 'OCI') {
                    this.r_508 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_508 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_508 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_508 = '#9fa1fd'
                    this.text_508 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_508 = '#d22625'
                    this.text_508 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_508 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_508 = '#de1fde'
                    this.text_508 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_508 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_508 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_508 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_508 = '#5f5f5f'
                    this.text_508 = 'text-white'
                } else{
                    this.r_508 = ''
                }
            } else if (rm_id === '509') {
                this.r_509_stat = stat

                if (stat === 'OCI') {
                    this.r_509 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_509 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_509 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_509 = '#9fa1fd'
                    this.text_509 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_509 = '#d22625'
                    this.text_509 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_509 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_509 = '#de1fde'
                    this.text_509 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_509 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_509 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_509 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_509 = '#5f5f5f'
                    this.text_509 = 'text-white'
                } else{
                    this.r_509 = ''
                }
            } else if (rm_id === '510') {
                this.r_510_stat = stat

                if (stat === 'OCI') {
                    this.r_510 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_510 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_510 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_510 = '#9fa1fd'
                    this.text_510 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_510 = '#d22625'
                    this.text_510 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_510 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_510 = '#de1fde'
                    this.text_510 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_510 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_510 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_510 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_510 = '#5f5f5f'
                    this.text_510 = 'text-white'
                } else{
                    this.r_510 = ''
                }
            } else if (rm_id === '511') {
                this.r_511_stat = stat

                if (stat === 'OCI') {
                    this.r_511 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_511 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_511 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_511 = '#9fa1fd'
                    this.text_511 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_511 = '#d22625'
                    this.text_511 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_511 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_511 = '#de1fde'
                    this.text_511 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_511 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_511 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_511 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_511 = '#5f5f5f'
                    this.text_511 = 'text-white'
                } else{
                    this.r_511 = ''
                }
            } else if (rm_id === '512') {
                this.r_512_stat = stat

                if (stat === 'OCI') {
                    this.r_512 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_512 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_512 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_512 = '#9fa1fd'
                    this.text_512 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_512 = '#d22625'
                    this.text_512 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_512 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_512 = '#de1fde'
                    this.text_512 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_512 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_512 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_512 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_512 = '#5f5f5f'
                    this.text_512 = 'text-white'
                } else{
                    this.r_512 = ''
                }
            } else if (rm_id === '514') {
                this.r_514_stat = stat

                if (stat === 'OCI') {
                    this.r_514 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_514 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_514 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_514 = '#9fa1fd'
                    this.text_514 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_514 = '#d22625'
                    this.text_514 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_514 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_514 = '#de1fde'
                    this.text_514 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_514 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_514 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_514 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_514 = '#5f5f5f'
                    this.text_514 = 'text-white'
                } else{
                    this.r_514 = ''
                }
            } else if (rm_id === '515') {
                this.r_515_stat = stat

                if (stat === 'OCI') {
                    this.r_515 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_515 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_515 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_515 = '#9fa1fd'
                    this.text_515 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_515 = '#d22625'
                    this.text_515 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_515 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_515 = '#de1fde'
                    this.text_515 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_515 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_515 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_515 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_515 = '#5f5f5f'
                    this.text_515 = 'text-white'
                } else{
                    this.r_515 = ''
                }
            } else if (rm_id === '516') {
                this.r_516_stat = stat

                if (stat === 'OCI') {
                    this.r_516 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_516 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_516 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_516 = '#9fa1fd'
                    this.text_516 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_516 = '#d22625'
                    this.text_516 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_516 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_516 = '#de1fde'
                    this.text_516 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_516 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_516 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_516 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_516 = '#5f5f5f'
                    this.text_516 = 'text-white'
                } else{
                    this.r_516 = ''
                }
            } else if (rm_id === '517') {
                this.r_517_stat = stat

                if (stat === 'OCI') {
                    this.r_517 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_517 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_517 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_517 = '#9fa1fd'
                    this.text_517 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_517 = '#d22625'
                    this.text_517 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_517 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_517 = '#de1fde'
                    this.text_517 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_517 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_517 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_517 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_517 = '#5f5f5f'
                    this.text_517 = 'text-white'
                } else{
                    this.r_517 = ''
                }
            } else if (rm_id === '518') {
                this.r_518_stat = stat

                if (stat === 'OCI') {
                    this.r_518 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_518 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_518 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_518 = '#9fa1fd'
                    this.text_518 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_518 = '#d22625'
                    this.text_518 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_518 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_518 = '#de1fde'
                    this.text_518 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_518 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_518 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_518 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_518 = '#5f5f5f'
                    this.text_518 = 'text-white'
                } else{
                    this.r_518 = ''
                }
            } else if (rm_id === '519') {
                this.r_519_stat = stat

                if (stat === 'OCI') {
                    this.r_519 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_519 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_519 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_519 = '#9fa1fd'
                    this.text_519 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_519 = '#d22625'
                    this.text_519 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_519 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_519 = '#de1fde'
                    this.text_519 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_519 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_519 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_519 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_519 = '#5f5f5f'
                    this.text_519 = 'text-white'
                } else{
                    this.r_519 = ''
                }
            } else if (rm_id === '520') {
                this.r_520_stat = stat

                if (stat === 'OCI') {
                    this.r_520 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_520 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_520 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_520 = '#9fa1fd'
                    this.text_520 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_520 = '#d22625'
                    this.text_520 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_520 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_520 = '#de1fde'
                    this.text_520 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_520 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_520 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_520 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_520 = '#5f5f5f'
                    this.text_520 = 'text-white'
                } else{
                    this.r_520 = ''
                }
            } else if (rm_id === '522') {
                this.r_522_stat = stat

                if (stat === 'OCI') {
                    this.r_522 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_522 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_522 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_522 = '#9fa1fd'
                    this.text_522 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_522 = '#d22625'
                    this.text_522 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_522 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_522 = '#de1fde'
                    this.text_522 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_522 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_522 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_522 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_522 = '#5f5f5f'
                    this.text_522 = 'text-white'
                } else{
                    this.r_522 = ''
                }
            } else if (rm_id === '524') {
                this.r_524_stat = stat

                if (stat === 'OCI') {
                    this.r_524 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_524 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_524 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_524 = '#9fa1fd'
                    this.text_524 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_524 = '#d22625'
                    this.text_524 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_524 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_524 = '#de1fde'
                    this.text_524 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_524 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_524 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_524 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_524 = '#5f5f5f'
                    this.text_524 = 'text-white'
                } else{
                    this.r_524 = ''
                }
            } else if (rm_id === '526') {
                this.r_526_stat = stat

                if (stat === 'OCI') {
                    this.r_526 = '#ffe2e5'
                } else if (stat === 'VCI'){
                    this.r_526 = '#a2ff9f'
                } else if (stat === 'VDI'){
                    this.r_526 = '#e6e691'
                } else if (stat === 'OCN'){
                    this.r_526 = '#9fa1fd'
                    this.text_526 = 'text-white'
                } else if (stat === 'ODI' || stat === 'ODN'){
                    this.r_526 = '#d22625'
                    this.text_526 = 'text-white'
                } else if (stat === 'VCI / VCN Block'){
                    this.r_526 = '#aafaf9'
                } else if (stat === 'Discrepency'){
                    this.r_526 = '#de1fde'
                    this.text_526 = 'text-white'
                } else if (stat === 'VCN'){
                    this.r_526 = '#ffdf9e'
                } else if (stat === 'Due Out'){
                    this.r_526 = '#e0e2e1'
                } else if (stat === 'VDN'){
                    this.r_526 = '#ffffa1'
                } else if (stat === 'Maintenance'){
                    this.r_526 = '#5f5f5f'
                    this.text_526 = 'text-white'
                } else{
                    this.r_526 = ''
                }
            }
        }
    },
    watch: {
    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
.v-slide-group__content {
  justify-content: center;
}
.h4{
    padding-top: 2px;
}

</style>