<template>
    <v-card class="rounded-l">
        <v-card-text>
            <v-container fluid>
                <v-row justify="center">
                    <v-col cols="10">
                        <h5 class="text-center text-weight" style="font-weight:600">
                            9 {{$t('labels.floor')}}
                        </h5>
                    </v-col>
                </v-row>
                <v-row class="mt-12 mb-12">
                    <v-col xs="5" sm="5" md="5">
                        <v-row>
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_901 ? r_901 : ''"
                                    @click="pilihKamar(901, r_901_stat)"
                                >
                                    <div class="text-tengah" :class="text_901 ? text_901 : ''">0901</div>
                                </v-btn>
                            </v-col>    
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_903 ? r_903 : ''"
                                    @click="pilihKamar(903, r_903_stat)"
                                >
                                    <div class="text-tengah" :class="text_903 ? text_903 : ''">0903</div>
                                </v-btn>
                            </v-col>  
                            <v-col cols="12" class="hidden-md-and-up">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col cols="12" class="hidden-md-and-up">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col>     
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_905 ? r_905 : ''"
                                    @click="pilihKamar(905, r_905_stat)"
                                >
                                    <div class="text-tengah" :class="text_905 ? text_905 : ''">0905</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_907 ? r_907 : ''"
                                    @click="pilihKamar(907, r_907_stat)"
                                >
                                    <div class="text-tengah" :class="text_907 ? text_907 : ''">0907</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_909 ? r_909 : ''"
                                    @click="pilihKamar(909, r_909_stat)"
                                >
                                    <div class="text-tengah" :class="text_909 ? text_909 : ''">0909</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_911 ? r_911 : ''"
                                    @click="pilihKamar(911, r_911_stat)"
                                >
                                    <div class="text-tengah" :class="text_911 ? text_911 : ''">0911</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_915 ? r_915 : ''"
                                    @click="pilihKamar(915, r_915_stat)"
                                >
                                    <div class="text-tengah" :class="text_915 ? text_915 : ''">0915</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_917 ? r_917 : ''"
                                    @click="pilihKamar(917, r_917_stat)"
                                >
                                    <div class="text-tengah" :class="text_917 ? text_917 : ''">0917</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_919 ? r_919 : ''"
                                    @click="pilihKamar(919, r_919_stat)"
                                >
                                    <div class="text-tengah" :class="text_919 ? text_919 : ''">0919</div>
                                </v-btn>
                            </v-col>                                                             
                        </v-row>
                    </v-col>
                    <v-col xs="2" sm="2" md="2">
                    </v-col>
                    <v-col xs="2" sm="5" md="5">
                        <v-row>
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_902 ? r_902 : ''"
                                    @click="pilihKamar(902, r_902_stat)"
                                >
                                    <div class="text-tengah" :class="text_902 ? text_902 : ''">0902</div>
                                </v-btn>
                            </v-col>    
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_906 ? r_906 : ''"
                                    @click="pilihKamar(906, r_906_stat)"
                                >
                                    <div class="text-tengah" :class="text_906 ? text_906 : ''">0906</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_908 ? r_908 : ''"
                                    @click="pilihKamar(908, r_908_stat)"
                                >
                                    <div class="text-tengah" :class="text_908 ? text_908 : ''">0908</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_910 ? r_910 : ''"
                                    @click="pilihKamar(910, r_910_stat)"
                                >
                                    <div class="text-tengah" :class="text_910 ? text_910 : ''">0910</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_912 ? r_912 : ''"
                                    @click="pilihKamar(912, r_912_stat)"
                                >
                                    <div class="text-tengah" :class="text_912 ? text_912 : ''">0912</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_914 ? r_914 : ''"
                                    @click="pilihKamar(914, r_914_stat)"
                                >
                                    <div class="text-tengah" :class="text_914 ? text_914 : ''">0914</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_916 ? r_916 : ''"
                                    @click="pilihKamar(916, r_916_stat)"
                                >
                                    <div class="text-tengah" :class="text_916 ? text_916 : ''">0916</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_918 ? r_918 : ''"
                                    @click="pilihKamar(918, r_918_stat)"
                                >
                                    <div class="text-tengah" :class="text_918 ? text_918 : ''">0918</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_920 ? r_920 : ''"
                                    @click="pilihKamar(920, r_920_stat)"
                                >
                                    <div class="text-tengah" :class="text_920 ? text_920 : ''">0920</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_922 ? r_922 : ''"
                                    @click="pilihKamar(922, r_922_stat)"
                                >
                                    <div class="text-tengah" :class="text_922 ? text_922 : ''">0922</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_924 ? r_924 : ''"
                                    @click="pilihKamar(924, r_924_stat)"
                                >
                                    <div class="text-tengah" :class="text_924 ? text_924 : ''">0924</div>
                                </v-btn>
                            </v-col>                                                            
                        </v-row>
                    </v-col>
                </v-row>
            
            </v-container>
        </v-card-text>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelCleaned')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VDI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_vdi"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelInspect')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_vdi = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VCI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_odn"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelCleaned')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_odn = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('ODI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_odi"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelInspect')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_odi = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('OCI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="loading"
            hide-overlay
            persistent
            width="300"
        >
            <v-card
                color="primary"
                dark
            >
                <v-card-text>
                {{$t('messages.loadingMessage')}}
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>
<script>
    export default {
        data() {
            return {
                selection: 0,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                breadcumbs: [
                    {
                    text: 'AYOLA',
                    disabled: false,
                    href: '/admin/ayala',
                    },
                    {
                    text: 'Dashboard',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Room Status',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                loading: false,
                focus: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                type: 'month',
                typeToLabel: {
                    month: 'Month',
                    week: 'Week',
                    day: 'Day',
                    '4day': '4 Days',
                },
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
                categories: ['Spesialis Konservasi Gigi', 'Spesialis Bedah Mulut'],
                events_name: [
                    {
                        color: "red",
                        end: '2022-09-28',
                        name: "Fuel Book",                   
                        start: '2022-09-28',
                        timed: true
                    },
                ],
                events: [
                    {
                        color: "red",
                        end: '2022-09-28',
                        name: "Fuel Book",                   
                        start: '2022-09-28',
                        timed: true
                    },
                ],
                colors: ['red', 'success'],
                names: ['Fuel Book', 'Available'],
                event: null,
                dialog_day_doctor: false,
                event_days: [],
                date: '',
                start_date: '',
                interval_count: null,
                choiceDate: [],
                display: 'block',
                displayItem: 'none',
                tab: 'tab-1',
                date: '',
                floor: 9,
                modal: false,
                date_from: '',
                r_901: '',
                r_901_stat: '',
                text_901: '',
                r_903: '',
                r_903_stat: '',
                text_903: '',
                r_905: '',
                r_905_stat: '',
                text_905: '',
                r_907: '',
                r_907_stat: '',
                text_907: '',
                r_909: '',
                r_909_stat: '',
                text_909: '',
                r_911: '',
                r_911_stat: '',
                text_911: '',
                r_915: '',
                r_915_stat: '',
                text_915: '',
                r_917: '',
                r_917_stat: '',
                text_917: '',
                r_919: '',
                r_919_stat: '',
                text_919: '',
                r_902: '',
                r_902_stat: '',
                text_902: '',
                r_906: '',
                r_906_stat: '',
                text_906: '',
                r_908: '',
                r_908_stat: '',
                text_908: '',
                r_910: '',
                r_910_stat: '',
                text_910: '',
                r_912: '',
                r_912_stat: '',
                text_912: '',
                r_914: '',
                r_914_stat: '',
                text_914: '',
                r_916: '',
                r_916_stat: '',
                text_916: '',
                r_918: '',
                r_918_stat: '',
                text_918: '',
                r_920: '',
                r_920_stat: '',
                text_920: '',
                r_922: '',
                r_922_stat: '',
                text_922: '',
                r_924: '',
                r_924_stat: '',
                text_924: '',
                r_926: '',
                r_926_stat: '',
                text_926: '',
                ruang: '',
                ruang_value: '',
                countDown: 60,
                dialog: false,
                file: [],
                desc_file:'',
                text_dialog: '',
                dialog_vdi: false,
                dialog_odi: false,
                dialog_odn: false,
                remark: ''
            }
        },
        mounted(){
            this.$store.dispatch('setOverlay', false)
            // this.date = Intl.DateTimeFormat('id', {timeZone: 'Asia/Jakarta', day: 'numeric', month: 'long', weekday: "long", year: "numeric"}).format()
            this.getDataFloor()
        },
        methods:{
            close(){
                this.$router.push('/admin/ayala');
            },
            choiceFloor(floor){
                this.floor = floor
            },
            pilihKamar(value, stat){
                console.log(stat);
                if (stat === 'VDN') {
                    this.dialog = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'VDI'){
                    this.dialog_vdi = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'ODI'){
                    this.dialog_odi = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'ODN'){
                    this.dialog_odn = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                }
            },
            clear(){
                this.remark = ''
                this.ruang = ''
                this.ruang_value = ''
                this.file = []
            },
            async submitStatus(status){
                this.loading = true

                let formData = new FormData()
                formData.append('office_id', 1);
                formData.append('tr_type', status.substr(1, 19));
                formData.append('rm_id', this.ruang_value);
                formData.append('dt_start', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
                formData.append('dt_end', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
                formData.append('remark', this.remark);
                formData.append('status', status);

                for( var i = 0; i < this.file.length; i++ ){
                    formData.append('file[' + i + ']', this.file[i]);
                }

                await axios.post(`${process.env.VUE_APP_URL}/api/ayola/room_hkeeping`, formData ,
                { 
                    headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
                }).then(res => {
                    this.clear()
                    this.loading = false

                    this.getDataFloor()
                    this.dialog = false
                    this.dialog_vdi = false

                    this.snackbar = {
                        color: "success",
                        icon: "mdi-checkbox-marked-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: "Successfully Update Data",
                        visible: true
                    };
                }).catch(err => {
                    this.loading = false
                    this.snackbar = {
                        color: "primary",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Info",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            },
            async getDataFloor(){
                this.$store.dispatch('setOverlay', true)

                await axios.get(`${process.env.VUE_APP_URL}/api/ayola/room/room_status?floor=${this.floor}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    for (let index = 0; index < res.data.data.length; index++) {
                        this.colorPickerCard(res.data.data[index].rm_id, res.data.data[index].stat)
                    }
                    setTimeout(()=>{
                    this.$store.dispatch('setOverlay', false)
                    },500);
                });
            },
            colorPickerCard(rm_id, stat) {
                if (rm_id === '901') {
                    this.r_901_stat = stat
                    if (stat === 'OCI') {
                        this.r_901 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_901 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_901 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_901 = '#9fa1fd'
                        this.text_901 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_901 = '#d22625'
                        this.text_901 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_901 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_901 = '#de1fde'
                        this.text_901 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_901 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_901 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_901 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_901 = '#5f5f5f'
                        this.text_901 = 'text-white'
                    } else{
                        this.r_901 = ''
                    }
                } else if (rm_id === '902') {
                    this.r_902_stat = stat
                    if (stat === 'OCI') {
                        this.r_902 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_902 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_902 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_902 = '#9fa1fd'
                        this.text_902 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_902 = '#d22625'
                        this.text_902 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_902 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_902 = '#de1fde'
                        this.text_902 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_902 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_902 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_902 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_902 = '#5f5f5f'
                        this.text_902 = 'text-white'
                    } else{
                        this.r_902 = ''
                    }
                } else if (rm_id === '903') {
                    this.r_903_stat = stat
                    if (stat === 'OCI') {
                        this.r_903 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_903 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_903 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_903 = '#9fa1fd'
                        this.text_903 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_903 = '#d22625'
                        this.text_903 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_903 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_903 = '#de1fde'
                        this.text_903 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_903 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_903 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_903 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_903 = '#5f5f5f'
                        this.text_903 = 'text-white'
                    } else{
                        this.r_903 = ''
                    }
                } else if (rm_id === '905') {
                    this.r_905_stat = stat
                    if (stat === 'OCI') {
                        this.r_905 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_905 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_905 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_905 = '#9fa1fd'
                        this.text_905 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_905 = '#d22625'
                        this.text_905 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_905 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_905 = '#de1fde'
                        this.text_905 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_905 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_905 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_905 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_905 = '#5f5f5f'
                        this.text_905 = 'text-white'
                    } else{
                        this.r_905 = ''
                    }
                } else if (rm_id === '906') {
                    this.r_906_stat = stat
                    if (stat === 'OCI') {
                        this.r_906 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_906 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_906 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_906 = '#9fa1fd'
                        this.text_906 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_906 = '#d22625'
                        this.text_906 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_906 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_906 = '#de1fde'
                        this.text_906 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_906 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_906 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_906 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_906 = '#5f5f5f'
                        this.text_906 = 'text-white'
                    } else{
                        this.r_906 = ''
                    }
                } else if (rm_id === '907') {
                    this.r_907_stat = stat
                    if (stat === 'OCI') {
                        this.r_907 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_907 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_907 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_907 = '#9fa1fd'
                        this.text_907 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_907 = '#d22625'
                        this.text_907 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_907 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_907 = '#de1fde'
                        this.text_907 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_907 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_907 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_907 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_907 = '#5f5f5f'
                        this.text_907 = 'text-white'
                    } else{
                        this.r_907 = ''
                    }
                } else if (rm_id === '908') {
                    this.r_908_stat = stat
                    if (stat === 'OCI') {
                        this.r_908 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_908 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_908 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_908 = '#9fa1fd'
                        this.text_908 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_908 = '#d22625'
                        this.text_908 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_908 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_908 = '#de1fde'
                        this.text_908 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_908 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_908 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_908 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_908 = '#5f5f5f'
                        this.text_908 = 'text-white'
                    } else{
                        this.r_908 = ''
                    }
                } else if (rm_id === '909') {
                    this.r_909_stat = stat
                    if (stat === 'OCI') {
                        this.r_909 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_909 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_909 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_909 = '#9fa1fd'
                        this.text_909 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_909 = '#d22625'
                        this.text_909 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_909 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_909 = '#de1fde'
                        this.text_909 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_909 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_909 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_909 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_909 = '#5f5f5f'
                        this.text_909 = 'text-white'
                    } else{
                        this.r_909 = ''
                    }
                } else if (rm_id === '910') {
                    this.r_910_stat = stat
                    if (stat === 'OCI') {
                        this.r_910 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_910 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_910 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_910 = '#9fa1fd'
                        this.text_910 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_910 = '#d22625'
                        this.text_910 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_910 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_910 = '#de1fde'
                        this.text_910 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_910 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_910 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_910 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_910 = '#5f5f5f'
                        this.text_910 = 'text-white'
                    } else{
                        this.r_910 = ''
                    }
                } else if (rm_id === '911') {
                    this.r_911_stat = stat
                    if (stat === 'OCI') {
                        this.r_911 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_911 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_911 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_911 = '#9fa1fd'
                        this.text_911 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_911 = '#d22625'
                        this.text_911 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_911 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_911 = '#de1fde'
                        this.text_911 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_911 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_911 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_911 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_911 = '#5f5f5f'
                        this.text_911 = 'text-white'
                    } else{
                        this.r_911 = ''
                    }
                } else if (rm_id === '912') {
                    this.r_912_stat = stat
                    if (stat === 'OCI') {
                        this.r_912 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_912 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_912 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_912 = '#9fa1fd'
                        this.text_912 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_912 = '#d22625'
                        this.text_912 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_912 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_912 = '#de1fde'
                        this.text_912 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_912 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_912 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_912 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_912 = '#5f5f5f'
                        this.text_912 = 'text-white'
                    } else{
                        this.r_912 = ''
                    }
                } else if (rm_id === '914') {
                    this.r_914_stat = stat
                    if (stat === 'OCI') {
                        this.r_914 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_914 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_914 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_914 = '#9fa1fd'
                        this.text_914 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_914 = '#d22625'
                        this.text_914 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_914 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_914 = '#de1fde'
                        this.text_914 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_914 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_914 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_914 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_914 = '#5f5f5f'
                        this.text_914 = 'text-white'
                    } else{
                        this.r_914 = ''
                    }
                } else if (rm_id === '915') {
                    this.r_915_stat = stat
                    if (stat === 'OCI') {
                        this.r_915 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_915 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_915 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_915 = '#9fa1fd'
                        this.text_915 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_915 = '#d22625'
                        this.text_915 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_915 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_915 = '#de1fde'
                        this.text_915 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_915 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_915 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_915 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_915 = '#5f5f5f'
                        this.text_915 = 'text-white'
                    } else{
                        this.r_915 = ''
                    }
                } else if (rm_id === '916') {
                    this.r_916_stat = stat
                    if (stat === 'OCI') {
                        this.r_916 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_916 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_916 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_916 = '#9fa1fd'
                        this.text_916 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_916 = '#d22625'
                        this.text_916 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_916 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_916 = '#de1fde'
                        this.text_916 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_916 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_916 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_916 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_916 = '#5f5f5f'
                        this.text_916 = 'text-white'
                    } else{
                        this.r_916 = ''
                    }
                } else if (rm_id === '917') {
                    this.r_917_stat = stat
                    if (stat === 'OCI') {
                        this.r_917 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_917 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_917 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_917 = '#9fa1fd'
                        this.text_917 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_917 = '#d22625'
                        this.text_917 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_917 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_917 = '#de1fde'
                        this.text_917 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_917 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_917 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_917 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_917 = '#5f5f5f'
                        this.text_917 = 'text-white'
                    } else{
                        this.r_917 = ''
                    }
                } else if (rm_id === '918') {
                    this.r_918_stat = stat
                    if (stat === 'OCI') {
                        this.r_918 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_918 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_918 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_918 = '#9fa1fd'
                        this.text_918 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_918 = '#d22625'
                        this.text_918 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_918 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_918 = '#de1fde'
                        this.text_918 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_918 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_918 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_918 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_918 = '#5f5f5f'
                        this.text_918 = 'text-white'
                    } else{
                        this.r_918 = ''
                    }
                } else if (rm_id === '919') {
                    this.r_919_stat = stat
                    if (stat === 'OCI') {
                        this.r_919 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_919 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_919 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_919 = '#9fa1fd'
                        this.text_919 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_919 = '#d22625'
                        this.text_919 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_919 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_919 = '#de1fde'
                        this.text_919 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_919 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_919 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_919 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_919 = '#5f5f5f'
                        this.text_919 = 'text-white'
                    } else{
                        this.r_919 = ''
                    }
                } else if (rm_id === '920') {
                    this.r_920_stat = stat
                    if (stat === 'OCI') {
                        this.r_920 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_920 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_920 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_920 = '#9fa1fd'
                        this.text_920 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_920 = '#d22625'
                        this.text_920 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_920 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_920 = '#de1fde'
                        this.text_920 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_920 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_920 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_920 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_920 = '#5f5f5f'
                        this.text_920 = 'text-white'
                    } else{
                        this.r_920 = ''
                    }
                } else if (rm_id === '922') {
                    this.r_922_stat = stat
                    if (stat === 'OCI') {
                        this.r_922 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_922 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_922 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_922 = '#9fa1fd'
                        this.text_922 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_922 = '#d22625'
                        this.text_922 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_922 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_922 = '#de1fde'
                        this.text_922 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_922 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_922 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_922 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_922 = '#5f5f5f'
                        this.text_922 = 'text-white'
                    } else{
                        this.r_922 = ''
                    }
                } else if (rm_id === '924') {
                    this.r_924_stat = stat
                    if (stat === 'OCI') {
                        this.r_924 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_924 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_924 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_924 = '#9fa1fd'
                        this.text_924 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_924 = '#d22625'
                        this.text_924 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_924 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_924 = '#de1fde'
                        this.text_924 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_924 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_924 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_924 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_924 = '#5f5f5f'
                        this.text_924 = 'text-white'
                    } else{
                        this.r_924 = ''
                    }
                }
            }
        },
        watch: {
        }
    }
    </script>
    <style scoped>
    .v-slide-group__content {
      justify-content: center;
    }
    </style>