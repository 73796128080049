<template>
    <v-card class="rounded-l">
        <v-card-text>
            <v-container fluid>
                <v-row justify="center">
                    <v-col cols="10">
                        <h5 class="text-center text-weight" style="font-weight:600">
                            7 {{$t('labels.floor')}}
                        </h5>
                    </v-col>
                </v-row>
                <v-row class="mt-12 mb-12">
                    <v-col xs="5" sm="5" md="5">
                        <v-row>
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_701 ? r_701 : ''"
                                    @click="pilihKamar(701, r_701_stat)"
                                >
                                    <div class="text-tengah" :class="text_701 ? text_701 : ''">0701</div>
                                </v-btn>
                            </v-col>    
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_703 ? r_703 : ''"
                                    @click="pilihKamar(703, r_703_stat)"
                                >
                                    <div class="text-tengah" :class="text_703 ? text_703 : ''">0703</div>
                                </v-btn>
                            </v-col>  
                            <v-col cols="12" class="hidden-md-and-up">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col cols="12" class="hidden-md-and-up">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col>     
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_705 ? r_705 : ''"
                                    @click="pilihKamar(705, r_705_stat)"
                                >
                                    <div class="text-tengah" :class="text_705 ? text_705 : ''">0705</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_707 ? r_707 : ''"
                                    @click="pilihKamar(707, r_707_stat)"
                                >
                                    <div class="text-tengah" :class="text_707 ? text_707 : ''">0707</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_709 ? r_709 : ''"
                                    @click="pilihKamar(709, r_709_stat)"
                                >
                                    <div class="text-tengah" :class="text_709 ? text_709 : ''">0709</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_711 ? r_711 : ''"
                                    @click="pilihKamar(711, r_711_stat)"
                                >
                                    <div class="text-tengah" :class="text_711 ? text_711 : ''">0711</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_715 ? r_715 : ''"
                                    @click="pilihKamar(715, r_715_stat)"
                                >
                                    <div class="text-tengah" :class="text_715 ? text_715 : ''">0715</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_717 ? r_717 : ''"
                                    @click="pilihKamar(717, r_717_stat)"
                                >
                                    <div class="text-tengah" :class="text_717 ? text_717 : ''">0717</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_719 ? r_719 : ''"
                                    @click="pilihKamar(719, r_719_stat)"
                                >
                                    <div class="text-tengah" :class="text_719 ? text_719 : ''">0719</div>
                                </v-btn>
                            </v-col>                                                             
                        </v-row>
                    </v-col>
                    <v-col xs="2" sm="2" md="2">
                    </v-col>
                    <v-col xs="5" sm="5" md="5">
                        <v-row>
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_702 ? r_702 : ''"
                                    @click="pilihKamar(702, r_702_stat)"
                                >
                                    <div class="text-tengah" :class="text_702 ? text_702 : ''">0702</div>
                                </v-btn>
                            </v-col>    
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_706 ? r_706 : ''"
                                    @click="pilihKamar(706, r_706_stat)"
                                >
                                    <div class="text-tengah" :class="text_706 ? text_706 : ''">0706</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_708 ? r_708 : ''"
                                    @click="pilihKamar(708, r_708_stat)"
                                >
                                    <div class="text-tengah" :class="text_708 ? text_708 : ''">0708</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_710 ? r_710 : ''"
                                    @click="pilihKamar(710, r_710_stat)"
                                >
                                    <div class="text-tengah" :class="text_710 ? text_710 : ''">0710</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_712 ? r_712 : ''"
                                    @click="pilihKamar(712, r_712_stat)"
                                >
                                    <div class="text-tengah" :class="text_712 ? text_712 : ''">0712</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_714 ? r_714 : ''"
                                    @click="pilihKamar(714, r_714_stat)"
                                >
                                    <div class="text-tengah" :class="text_714 ? text_714 : ''">0714</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_716 ? r_716 : ''"
                                    @click="pilihKamar(716, r_716_stat)"
                                >
                                    <div class="text-tengah" :class="text_716 ? text_716 : ''">0716</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_718 ? r_718 : ''"
                                    @click="pilihKamar(718, r_718_stat)"
                                >
                                    <div class="text-tengah" :class="text_718 ? text_718 : ''">0718</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_720 ? r_720 : ''"
                                    @click="pilihKamar(720, r_720_stat)"
                                >
                                    <div class="text-tengah" :class="text_720 ? text_720 : ''">0720</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_722 ? r_722 : ''"
                                    @click="pilihKamar(722, r_722_stat)"
                                >
                                    <div class="text-tengah" :class="text_722 ? text_722 : ''">0722</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_724 ? r_724 : ''"
                                    @click="pilihKamar(724, r_724_stat)"
                                >
                                    <div class="text-tengah" :class="text_724 ? text_724 : ''">0724</div>
                                </v-btn>
                            </v-col>                                                               
                        </v-row>
                    </v-col>
                </v-row>
            
            </v-container>
        </v-card-text>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelCleaned')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VDI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_vdi"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelInspect')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_vdi = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VCI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_odn"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelCleaned')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_odn = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('ODI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_odi"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelInspect')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_odi = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('OCI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="loading"
            hide-overlay
            persistent
            width="300"
        >
            <v-card
                color="primary"
                dark
            >
                <v-card-text>
                {{$t('messages.loadingMessage')}}
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>
<script>
    export default {
        data() {
            return {
                selection: 0,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                breadcumbs: [
                    {
                    text: 'AYOLA',
                    disabled: false,
                    href: '/admin/ayala',
                    },
                    {
                    text: 'Dashboard',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Room Status',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                loading: false,
                focus: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                type: 'month',
                typeToLabel: {
                    month: 'Month',
                    week: 'Week',
                    day: 'Day',
                    '4day': '4 Days',
                },
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
                categories: ['Spesialis Konservasi Gigi', 'Spesialis Bedah Mulut'],
                events_name: [
                    {
                        color: "red",
                        end: '2022-09-28',
                        name: "Fuel Book",                   
                        start: '2022-09-28',
                        timed: true
                    },
                ],
                events: [
                    {
                        color: "red",
                        end: '2022-09-28',
                        name: "Fuel Book",                   
                        start: '2022-09-28',
                        timed: true
                    },
                ],
                colors: ['red', 'success'],
                names: ['Fuel Book', 'Available'],
                event: null,
                dialog_day_doctor: false,
                event_days: [],
                date: '',
                start_date: '',
                interval_count: null,
                choiceDate: [],
                display: 'block',
                displayItem: 'none',
                tab: 'tab-1',
                date: '',
                floor: 7,
                modal: false,
                date_from: '',
                r_701: '',
                r_701_stat: '',
                text_701: '',
                r_703: '',
                r_703_stat: '',
                text_703: '',
                r_705: '',
                r_705_stat: '',
                text_705: '',
                r_707: '',
                r_707_stat: '',
                text_707: '',
                r_709: '',
                r_709_stat: '',
                text_709: '',
                r_711: '',
                r_711_stat: '',
                text_711: '',
                r_715: '',
                r_715_stat: '',
                text_715: '',
                r_717: '',
                r_717_stat: '',
                text_717: '',
                r_719: '',
                r_719_stat: '',
                text_719: '',
                r_702: '',
                r_702_stat: '',
                text_702: '',
                r_706: '',
                r_706_stat: '',
                text_706: '',
                r_708: '',
                r_708_stat: '',
                text_708: '',
                r_710: '',
                r_710_stat: '',
                text_710: '',
                r_712: '',
                r_712_stat: '',
                text_712: '',
                r_714: '',
                r_714_stat: '',
                text_714: '',
                r_716: '',
                r_716_stat: '',
                text_716: '',
                r_718: '',
                r_718_stat: '',
                text_718: '',
                r_720: '',
                r_720_stat: '',
                text_720: '',
                r_722: '',
                r_722_stat: '',
                text_722: '',
                r_724: '',
                r_724_stat: '',
                text_724: '',
                r_726: '',
                r_726_stat: '',
                text_726: '',
                ruang: '',
                ruang_value: '',
                countDown: 60,
                dialog: false,
                file: [],
                desc_file:'',
                text_dialog: '',
                dialog_vdi: false,
                dialog_odi: false,
                dialog_odn: false,
                remark: ''
            }
        },
        mounted(){
            this.$store.dispatch('setOverlay', false)
            // this.date = Intl.DateTimeFormat('id', {timeZone: 'Asia/Jakarta', day: 'numeric', month: 'long', weekday: "long", year: "numeric"}).format()
            this.getDataFloor()
        },
        methods:{
            close(){
                this.$router.push('/admin/ayala');
            },
            choiceFloor(floor){
                this.floor = floor
            },
            pilihKamar(value, stat){
                console.log(stat);
                if (stat === 'VDN') {
                    this.dialog = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'VDI'){
                    this.dialog_vdi = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'ODI'){
                    this.dialog_odi = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'ODN'){
                    this.dialog_odn = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                }
            },
            clear(){
                this.remark = ''
                this.ruang = ''
                this.ruang_value = ''
                this.file = []
            },
            async submitStatus(status){
                this.loading = true

                let formData = new FormData()
                formData.append('office_id', 1);
                formData.append('tr_type', status.substr(1, 19));
                formData.append('rm_id', this.ruang_value);
                formData.append('dt_start', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
                formData.append('dt_end', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
                formData.append('remark', this.remark);
                formData.append('status', status);

                for( var i = 0; i < this.file.length; i++ ){
                    formData.append('file[' + i + ']', this.file[i]);
                }

                await axios.post(`${process.env.VUE_APP_URL}/api/ayola/room_hkeeping`, formData ,
                { 
                    headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
                }).then(res => {
                    this.clear()
                    this.loading = false

                    this.getDataFloor()
                    this.dialog = false
                    this.dialog_vdi = false

                    this.snackbar = {
                        color: "success",
                        icon: "mdi-checkbox-marked-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: "Successfully Update Data",
                        visible: true
                    };
                }).catch(err => {
                    this.loading = false
                    this.snackbar = {
                        color: "primary",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Info",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            },
            async getDataFloor(){
                this.$store.dispatch('setOverlay', true)

                await axios.get(`${process.env.VUE_APP_URL}/api/ayola/room/room_status?floor=${this.floor}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    for (let index = 0; index < res.data.data.length; index++) {
                        this.colorPickerCard(res.data.data[index].rm_id, res.data.data[index].stat)
                    }

                    setTimeout(()=>{
                    this.$store.dispatch('setOverlay', false)
                    },500);
                });
            },
            colorPickerCard(rm_id, stat) {
                if (rm_id === '701') {
                    this.r_701_stat = stat
                    if (stat === 'OCI') {
                        this.r_701 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_701 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_701 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_701 = '#9fa1fd'
                        this.text_701 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_701 = '#d22625'
                        this.text_701 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_701 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_701 = '#de1fde'
                        this.text_701 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_701 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_701 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_701 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_701 = '#5f5f5f'
                        this.text_701 = 'text-white'
                    } else{
                        this.r_701 = ''
                    }
                } else if (rm_id === '702') {
                    this.r_702_stat = stat
                    if (stat === 'OCI') {
                        this.r_702 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_702 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_702 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_702 = '#9fa1fd'
                        this.text_702 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_702 = '#d22625'
                        this.text_702 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_702 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_702 = '#de1fde'
                        this.text_702 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_702 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_702 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_702 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_702 = '#5f5f5f'
                        this.text_702 = 'text-white'
                    } else{
                        this.r_702 = ''
                    }
                } else if (rm_id === '703') {
                    this.r_703_stat = stat
                    if (stat === 'OCI') {
                        this.r_703 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_703 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_703 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_703 = '#9fa1fd'
                        this.text_703 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_703 = '#d22625'
                        this.text_703 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_703 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_703 = '#de1fde'
                        this.text_703 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_703 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_703 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_703 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_703 = '#5f5f5f'
                        this.text_703 = 'text-white'
                    } else{
                        this.r_703 = ''
                    }
                } else if (rm_id === '705') {
                    this.r_705_stat = stat
                    if (stat === 'OCI') {
                        this.r_705 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_705 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_705 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_705 = '#9fa1fd'
                        this.text_705 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_705 = '#d22625'
                        this.text_705 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_705 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_705 = '#de1fde'
                        this.text_705 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_705 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_705 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_705 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_705 = '#5f5f5f'
                        this.text_705 = 'text-white'
                    } else{
                        this.r_705 = ''
                    }
                } else if (rm_id === '706') {
                    this.r_706_stat = stat
                    if (stat === 'OCI') {
                        this.r_706 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_706 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_706 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_706 = '#9fa1fd'
                        this.text_706 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_706 = '#d22625'
                        this.text_706 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_706 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_706 = '#de1fde'
                        this.text_706 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_706 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_706 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_706 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_706 = '#5f5f5f'
                        this.text_706 = 'text-white'
                    } else{
                        this.r_706 = ''
                    }
                } else if (rm_id === '707') {
                    this.r_707_stat = stat
                    if (stat === 'OCI') {
                        this.r_707 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_707 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_707 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_707 = '#9fa1fd'
                        this.text_707 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_707 = '#d22625'
                        this.text_707 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_707 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_707 = '#de1fde'
                        this.text_707 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_707 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_707 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_707 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_707 = '#5f5f5f'
                        this.text_707 = 'text-white'
                    } else{
                        this.r_707 = ''
                    }
                } else if (rm_id === '708') {
                    this.r_708_stat = stat
                    if (stat === 'OCI') {
                        this.r_708 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_708 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_708 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_708 = '#9fa1fd'
                        this.text_708 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_708 = '#d22625'
                        this.text_708 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_708 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_708 = '#de1fde'
                        this.text_708 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_708 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_708 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_708 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_708 = '#5f5f5f'
                        this.text_708 = 'text-white'
                    } else{
                        this.r_708 = ''
                    }
                } else if (rm_id === '709') {
                    this.r_709_stat = stat
                    if (stat === 'OCI') {
                        this.r_709 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_709 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_709 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_709 = '#9fa1fd'
                        this.text_709 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_709 = '#d22625'
                        this.text_709 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_709 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_709 = '#de1fde'
                        this.text_709 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_709 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_709 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_709 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_709 = '#5f5f5f'
                        this.text_709 = 'text-white'
                    } else{
                        this.r_709 = ''
                    }
                } else if (rm_id === '710') {
                    this.r_710_stat = stat
                    if (stat === 'OCI') {
                        this.r_710 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_710 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_710 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_710 = '#9fa1fd'
                        this.text_710 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_710 = '#d22625'
                        this.text_710 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_710 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_710 = '#de1fde'
                        this.text_710 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_710 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_710 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_710 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_710 = '#5f5f5f'
                        this.text_710 = 'text-white'
                    } else{
                        this.r_710 = ''
                    }
                } else if (rm_id === '711') {
                    this.r_711_stat = stat
                    if (stat === 'OCI') {
                        this.r_711 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_711 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_711 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_711 = '#9fa1fd'
                        this.text_711 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_711 = '#d22625'
                        this.text_711 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_711 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_711 = '#de1fde'
                        this.text_711 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_711 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_711 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_711 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_711 = '#5f5f5f'
                        this.text_711 = 'text-white'
                    } else{
                        this.r_711 = ''
                    }
                } else if (rm_id === '712') {
                    this.r_712_stat = stat
                    if (stat === 'OCI') {
                        this.r_712 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_712 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_712 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_712 = '#9fa1fd'
                        this.text_712 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_712 = '#d22625'
                        this.text_712 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_712 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_712 = '#de1fde'
                        this.text_712 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_712 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_712 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_712 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_712 = '#5f5f5f'
                        this.text_712 = 'text-white'
                    } else{
                        this.r_712 = ''
                    }
                } else if (rm_id === '714') {
                    this.r_714_stat = stat
                    if (stat === 'OCI') {
                        this.r_714 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_714 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_714 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_714 = '#9fa1fd'
                        this.text_714 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_714 = '#d22625'
                        this.text_714 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_714 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_714 = '#de1fde'
                        this.text_714 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_714 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_714 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_714 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_714 = '#5f5f5f'
                        this.text_714 = 'text-white'
                    } else{
                        this.r_714 = ''
                    }
                } else if (rm_id === '715') {
                    this.r_715_stat = stat
                    if (stat === 'OCI') {
                        this.r_715 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_715 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_715 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_715 = '#9fa1fd'
                        this.text_715 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_715 = '#d22625'
                        this.text_715 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_715 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_715 = '#de1fde'
                        this.text_715 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_715 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_715 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_715 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_715 = '#5f5f5f'
                        this.text_715 = 'text-white'
                    } else{
                        this.r_715 = ''
                    }
                } else if (rm_id === '716') {
                    this.r_716_stat = stat
                    if (stat === 'OCI') {
                        this.r_716 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_716 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_716 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_716 = '#9fa1fd'
                        this.text_716 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_716 = '#d22625'
                        this.text_716 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_716 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_716 = '#de1fde'
                        this.text_716 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_716 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_716 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_716 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_716 = '#5f5f5f'
                        this.text_716 = 'text-white'
                    } else{
                        this.r_716 = ''
                    }
                } else if (rm_id === '717') {
                    this.r_717_stat = stat
                    if (stat === 'OCI') {
                        this.r_717 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_717 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_717 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_717 = '#9fa1fd'
                        this.text_717 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_717 = '#d22625'
                        this.text_717 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_717 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_717 = '#de1fde'
                        this.text_717 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_717 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_717 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_717 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_717 = '#5f5f5f'
                        this.text_717 = 'text-white'
                    } else{
                        this.r_717 = ''
                    }
                } else if (rm_id === '718') {
                    this.r_718_stat = stat
                    if (stat === 'OCI') {
                        this.r_718 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_718 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_718 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_718 = '#9fa1fd'
                        this.text_718 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_718 = '#d22625'
                        this.text_718 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_718 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_718 = '#de1fde'
                        this.text_718 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_718 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_718 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_718 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_718 = '#5f5f5f'
                        this.text_718 = 'text-white'
                    } else{
                        this.r_718 = ''
                    }
                } else if (rm_id === '719') {
                    this.r_719_stat = stat
                    if (stat === 'OCI') {
                        this.r_719 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_719 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_719 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_719 = '#9fa1fd'
                        this.text_719 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_719 = '#d22625'
                        this.text_719 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_719 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_719 = '#de1fde'
                        this.text_719 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_719 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_719 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_719 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_719 = '#5f5f5f'
                        this.text_719 = 'text-white'
                    } else{
                        this.r_719 = ''
                    }
                } else if (rm_id === '720') {
                    this.r_720_stat = stat
                    if (stat === 'OCI') {
                        this.r_720 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_720 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_720 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_720 = '#9fa1fd'
                        this.text_720 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_720 = '#d22625'
                        this.text_720 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_720 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_720 = '#de1fde'
                        this.text_720 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_720 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_720 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_720 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_720 = '#5f5f5f'
                        this.text_720 = 'text-white'
                    } else{
                        this.r_720 = ''
                    }
                } else if (rm_id === '722') {
                    this.r_722_stat = stat
                    if (stat === 'OCI') {
                        this.r_722 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_722 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_722 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_722 = '#9fa1fd'
                        this.text_722 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_722 = '#d22625'
                        this.text_722 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_722 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_722 = '#de1fde'
                        this.text_722 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_722 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_722 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_722 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_722 = '#5f5f5f'
                        this.text_722 = 'text-white'
                    } else{
                        this.r_722 = ''
                    }
                } else if (rm_id === '724') {
                    this.r_724_stat = stat
                    if (stat === 'OCI') {
                        this.r_724 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_724 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_724 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_724 = '#9fa1fd'
                        this.text_724 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_724 = '#d22625'
                        this.text_724 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_724 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_724 = '#de1fde'
                        this.text_724 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_724 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_724 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_724 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_724 = '#5f5f5f'
                        this.text_724 = 'text-white'
                    } else{
                        this.r_724 = ''
                    }
                }
            }
        },
        watch: {
        }
    }
    </script>
    <style scoped>
    .v-slide-group__content {
      justify-content: center;
    }
    </style>