<template>
    <v-card class="rounded-l">
        <v-card-text>
            <v-container fluid>
                <v-row justify="center">
                    <v-col cols="10">
                        <h5 class="text-center text-weight" style="font-weight:600">
                            10 {{$t('labels.floor')}}
                        </h5>
                    </v-col>
                </v-row>
                <v-row class="mt-12 mb-12">
                    <v-col xs="5" sm="5" md="5">
                        <v-row>
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1001 ? r_1001 : ''"
                                    @click="pilihKamar(1001, r_1001_stat)"
                                >
                                    <div class="text-tengah" :class="text_1001 ? text_1001 : ''">1001</div>
                                </v-btn>
                            </v-col>    
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1003 ? r_1003 : ''"
                                    @click="pilihKamar(1003, r_1003_stat)"
                                >
                                    <div class="text-tengah" :class="text_1003 ? text_1003 : ''">1003</div>
                                </v-btn>
                            </v-col>  
                            <v-col cols="12" class="hidden-md-and-up">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col cols="12" class="hidden-md-and-up">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6" class="hidden-sm-and-down">
                                <v-btn
                                    block
                                    disabled
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                >
                                </v-btn>
                            </v-col>    
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1005 ? r_1005 : ''"
                                    @click="pilihKamar(1005, r_1005_stat)"
                                >
                                    <div class="text-tengah" :class="text_1005 ? text_1005 : ''">1005</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1007 ? r_1007 : ''"
                                    @click="pilihKamar(1007, r_1007_stat)"
                                >
                                    <div class="text-tengah" :class="text_1007 ? text_1007 : ''">1007</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1009 ? r_1009 : ''"
                                    @click="pilihKamar(1009, r_1009_stat)"
                                >
                                    <div class="text-tengah" :class="text_1009 ? text_1009 : ''">1009</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1011 ? r_1011 : ''"
                                    @click="pilihKamar(1011, r_1011_stat)"
                                >
                                    <div class="text-tengah" :class="text_1011 ? text_1011 : ''">1011</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1015 ? r_1015 : ''"
                                    @click="pilihKamar(1015, r_1015_stat)"
                                >
                                    <div class="text-tengah" :class="text_1015 ? text_1015 : ''">1015</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1017 ? r_1017 : ''"
                                    @click="pilihKamar(1017, r_1017_stat)"
                                >
                                    <div class="text-tengah" :class="text_1017 ? text_1017 : ''">1017</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1019 ? r_1019 : ''"
                                    @click="pilihKamar(1019, r_1019_stat)"
                                >
                                    <div class="text-tengah" :class="text_1019 ? text_1019 : ''">1019</div>
                                </v-btn>
                            </v-col>                                                             
                        </v-row>
                    </v-col>
                    <v-col xs="2" sm="2" md="2">
                    </v-col>
                    <v-col xs="5" sm="5" md="5">
                        <v-row>  
                            
                            <v-col cols="12" xs="12" sm="12" md="12" class="hidden-sm-and-down">
                                <!-- <v-card outlined class="rounded-xl" :color="r_1002 ? r_1002 : ''" @click="pilihKamar(1002, r_1002_stat)">
                                    <v-card-text>
                                        <h5 class="text-center" :class="text_1002 ? text_1002 : ''">1002</h5>
                                    </v-card-text>
                                </v-card> -->
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1002 ? r_1002 : ''"
                                    @click="pilihKamar(1002, r_1002_stat)"
                                >
                                    <div class="text-tengah" :class="text_1002 ? text_1002 : ''">1002</div>
                                </v-btn>
                            </v-col> 
                            <v-col cols="12" xs="12" sm="12" md="12" class="hidden-md-and-up">
                                <!-- <v-card outlined class="rounded-xl" height="142px" :color="r_1002 ? r_1002 : ''" @click="pilihKamar(1002, r_1002_stat)">
                                    <v-card-text align="center" style="margin-top: 38px;">
                                        <h5 class="text-center" :class="text_1002 ? text_1002 : ''">1002</h5>
                                    </v-card-text>
                                </v-card> -->
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    height="114px"
                                    :color="r_1002 ? r_1002 : ''"
                                    @click="pilihKamar(1002, r_1002_stat)"
                                >
                                    <div class="text-tengah" :class="text_1002 ? text_1002 : ''">1002</div>
                                </v-btn>
                            </v-col>  

                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1006 ? r_1006 : ''"
                                    @click="pilihKamar(1006, r_1006_stat)"
                                >
                                    <div class="text-tengah" :class="text_1006 ? text_1006 : ''">1006</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1008 ? r_1008 : ''"
                                    @click="pilihKamar(1008, r_1008_stat)"
                                >
                                    <div class="text-tengah" :class="text_1008 ? text_1008 : ''">1008</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1010 ? r_1010 : ''"
                                    @click="pilihKamar(1010, r_1010_stat)"
                                >
                                    <div class="text-tengah" :class="text_1010 ? text_1010 : ''">1010</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1012 ? r_1012 : ''"
                                    @click="pilihKamar(1012, r_1012_stat)"
                                >
                                    <div class="text-tengah" :class="text_1012 ? text_1012 : ''">1012</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1014 ? r_1014 : ''"
                                    @click="pilihKamar(1014, r_1014_stat)"
                                >
                                    <div class="text-tengah" :class="text_1014 ? text_1014 : ''">1014</div>
                                </v-btn>
                            </v-col>  
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1016 ? r_1016 : ''"
                                    @click="pilihKamar(1016, r_1016_stat)"
                                >
                                    <div class="text-tengah" :class="text_1016 ? text_1016 : ''">1016</div>
                                </v-btn>
                            </v-col>   
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1018 ? r_1018 : ''"
                                    @click="pilihKamar(1018, r_1018_stat)"
                                >
                                    <div class="text-tengah" :class="text_1018 ? text_1018 : ''">1018</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1020 ? r_1020 : ''"
                                    @click="pilihKamar(1020, r_1020_stat)"
                                >
                                    <div class="text-tengah" :class="text_1020 ? text_1020 : ''">1020</div>
                                </v-btn>
                            </v-col> 
                            <v-col xs="6" sm="6" md="6">
                                <v-btn
                                    block
                                    elevation="2"
                                    x-large
                                    rounded
                                    center
                                    :color="r_1022 ? r_1022 : ''"
                                    @click="pilihKamar(1022, r_1022_stat)"
                                >
                                    <div class="text-tengah" :class="text_1022 ? text_1022 : ''">1022</div>
                                </v-btn>
                            </v-col>                                                            
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelCleaned')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VDI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_vdi"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelInspect')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_vdi = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('VCI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_odn"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelCleaned')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_odn = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('ODI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_odi"
            persistent
            max-width="600px"
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h4>
                                {{$t('labels.labelInspect')}} {{ruang}}?
                            </h4>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="mt-5">
                        <v-row align="center" justify="center">
                            <v-col cols="12" xs="12" sm="2" md="2" class="text-center">
                                <v-icon x-large color="primary" class="text-center">
                                    mdi-check-bold
                                </v-icon>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-5 mb-2">
                            <v-col 
                                cols="12"
                            >
                                <v-file-input
                                    solo
                                    v-model="file"
                                    :label="$t('labels.pictureFile')"
                                    small-chips
                                    counter
                                    multiple
                                    append-icon="mdi-image"
                                    class="border-12"
                                    accept="image/*"
                                    height="80"
                                    hide-details=true
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="12" class="mt-3"
                            >
                                <h6 class="blue-lcd">{{$t('labels.note')}}</h6>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    :label="$t('labels.note')"
                                    v-model="remark"
                                    class="mt-2 border-12"
                                    hide-details=true
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l p-4 mr-2" color="#fff" @click="dialog_odi = false">{{$t('buttons.cancel')}}</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="4">
                                <v-btn block class="rounded-l text-white p-4" color="#003871" :disabled="loading" :loading="loading" @click="submitStatus('OCI')">{{$t('buttons.save')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="loading"
            hide-overlay
            persistent
            width="300"
        >
            <v-card
                color="primary"
                dark
            >
                <v-card-text>
                {{$t('messages.loadingMessage')}}
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>
<script>
    export default {
        data() {
            return {
                selection: 0,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                breadcumbs: [
                    {
                    text: 'AYOLA',
                    disabled: false,
                    href: '/admin/ayala',
                    },
                    {
                    text: 'Dashboard',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Room Status',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                loading: false,
                focus: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                type: 'month',
                typeToLabel: {
                    month: 'Month',
                    week: 'Week',
                    day: 'Day',
                    '4day': '4 Days',
                },
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
                categories: ['Spesialis Konservasi Gigi', 'Spesialis Bedah Mulut'],
                events_name: [
                    {
                        color: "red",
                        end: '2022-09-28',
                        name: "Fuel Book",                   
                        start: '2022-09-28',
                        timed: true
                    },
                ],
                events: [
                    {
                        color: "red",
                        end: '2022-09-28',
                        name: "Fuel Book",                   
                        start: '2022-09-28',
                        timed: true
                    },
                ],
                colors: ['red', 'success'],
                names: ['Fuel Book', 'Available'],
                event: null,
                dialog_day_doctor: false,
                event_days: [],
                date: '',
                start_date: '',
                interval_count: null,
                choiceDate: [],
                display: 'block',
                displayItem: 'none',
                tab: 'tab-1',
                date: '',
                floor: 10,
                modal: false,
                date_from: '',
                r_1001: '',
                r_1001_stat: '',
                text_1001: '',
                r_1003: '',
                r_1003_stat: '',
                text_1003: '',
                r_1005: '',
                r_1005_stat: '',
                text_1005: '',
                r_1007: '',
                r_1007_stat: '',
                text_1007: '',
                r_1009: '',
                r_1009_stat: '',
                text_1009: '',
                r_1011: '',
                r_1011_stat: '',
                text_1011: '',
                r_1015: '',
                r_1015_stat: '',
                text_1015: '',
                r_1017: '',
                r_1017_stat: '',
                text_1017: '',
                r_1019: '',
                r_1019_stat: '',
                text_1019: '',
                r_1002: '',
                r_1002_stat: '',
                text_1002: '',
                r_1006: '',
                r_1006_stat: '',
                text_1006: '',
                r_1008: '',
                r_1008_stat: '',
                text_1008: '',
                r_1010: '',
                r_1010_stat: '',
                text_1010: '',
                r_1012: '',
                r_1012_stat: '',
                text_1012: '',
                r_1014: '',
                r_1014_stat: '',
                text_1014: '',
                r_1016: '',
                r_1016_stat: '',
                text_1016: '',
                r_1018: '',
                r_1018_stat: '',
                text_1018: '',
                r_1020: '',
                r_1020_stat: '',
                text_1020: '',
                r_1022: '',
                r_1022_stat: '',
                text_1022: '',
                r_1024: '',
                r_1024_stat: '',
                text_1024: '',
                r_1026: '',
                r_1026_stat: '',
                text_1026: '',
                ruang: '',
                ruang_value: '',
                countDown: 60,
                dialog: false,
                file: [],
                desc_file:'',
                text_dialog: '',
                dialog_vdi: false,
                dialog_odi: false,
                dialog_odn: false,
                remark: ''
            }
        },
        mounted(){
            this.$store.dispatch('setOverlay', false)
            // this.date = Intl.DateTimeFormat('id', {timeZone: 'Asia/Jakarta', day: 'numeric', month: 'long', weekday: "long", year: "numeric"}).format()
            this.getDataFloor()
        },
        methods:{
            close(){
                this.$router.push('/admin/ayala');
            },
            choiceFloor(floor){
                this.floor = floor
            },
            pilihKamar(value, stat){
                console.log(stat);
                if (stat === 'VDN') {
                    this.dialog = true
                    this.ruang_value = value
                    this.ruang = value
                } else if(stat === 'VDI'){
                    this.dialog_vdi = true
                    this.ruang_value = value
                    this.ruang = value
                } else if(stat === 'ODI'){
                    this.dialog_odi = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                } else if(stat === 'ODN'){
                    this.dialog_odn = true
                    this.ruang_value = value
                    this.ruang = '0' + value
                }
            },
            clear(){
                this.remark = ''
                this.ruang = ''
                this.ruang_value = ''
                this.file = []
            },
            async submitStatus(status){
                this.loading = true

                let formData = new FormData()
                formData.append('office_id', 1);
                formData.append('tr_type', status.substr(1, 19));
                formData.append('rm_id', this.ruang_value);
                formData.append('dt_start', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
                formData.append('dt_end', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 19));
                formData.append('remark', this.remark);
                formData.append('status', status);

                for( var i = 0; i < this.file.length; i++ ){
                    formData.append('file[' + i + ']', this.file[i]);
                }

                await axios.post(`${process.env.VUE_APP_URL}/api/ayola/room_hkeeping`, formData ,
                { 
                    headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
                }).then(res => {
                    this.clear()
                    this.loading = false

                    this.getDataFloor()
                    this.dialog = false
                    this.dialog_vdi = false
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-checkbox-marked-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: "Successfully Update Data",
                        visible: true
                    };
                }).catch(err => {
                    this.loading = false
                    this.snackbar = {
                        color: "primary",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Info",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            },
            async getDataFloor(){
                this.$store.dispatch('setOverlay', true)

                await axios.get(`${process.env.VUE_APP_URL}/api/ayola/room/room_status?floor=${this.floor}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    for (let index = 0; index < res.data.data.length; index++) {
                        this.colorPickerCard(res.data.data[index].rm_id, res.data.data[index].stat)
                    }
                    setTimeout(()=>{
                    this.$store.dispatch('setOverlay', false)
                    },500);
                });
            },
            colorPickerCard(rm_id, stat) {
                if (rm_id === '1001') {
                    this.r_1001_stat = stat
                    if (stat === 'OCI') {
                        this.r_1001 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1001 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1001 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1001 = '#9fa1fd'
                        this.text_1001 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1001 = '#d22625'
                        this.text_1001 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1001 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1001 = '#de1fde'
                        this.text_1001 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1001 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1001 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1001 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1001 = '#5f5f5f'
                        this.text_1001 = 'text-white'
                    } else{
                        this.r_1001 = ''
                    }
                } else if (rm_id === '1002') {
                    this.r_1002_stat = stat
                    if (stat === 'OCI') {
                        this.r_1002 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1002 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1002 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1002 = '#9fa1fd'
                        this.text_1002 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1002 = '#d22625'
                        this.text_1002 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1002 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1002 = '#de1fde'
                        this.text_1002 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1002 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1002 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1002 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1002 = '#5f5f5f'
                        this.text_1002 = 'text-white'
                    } else{
                        this.r_1002 = ''
                    }
                } else if (rm_id === '1003') {
                    this.r_1003_stat = stat
                    if (stat === 'OCI') {
                        this.r_1003 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1003 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1003 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1003 = '#9fa1fd'
                        this.text_1003 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1003 = '#d22625'
                        this.text_1003 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1003 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1003 = '#de1fde'
                        this.text_1003 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1003 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1003 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1003 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1003 = '#5f5f5f'
                        this.text_1003 = 'text-white'
                    } else{
                        this.r_1003 = ''
                    }
                } else if (rm_id === '1005') {
                    this.r_1005_stat = stat
                    if (stat === 'OCI') {
                        this.r_1005 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1005 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1005 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1005 = '#9fa1fd'
                        this.text_1005 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1005 = '#d22625'
                        this.text_1005 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1005 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1005 = '#de1fde'
                        this.text_1005 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1005 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1005 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1005 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1005 = '#5f5f5f'
                        this.text_1005 = 'text-white'
                    } else{
                        this.r_1005 = ''
                    }
                } else if (rm_id === '1006') {
                    this.r_1006_stat = stat
                    if (stat === 'OCI') {
                        this.r_1006 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1006 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1006 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1006 = '#9fa1fd'
                        this.text_1006 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1006 = '#d22625'
                        this.text_1006 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1006 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1006 = '#de1fde'
                        this.text_1006 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1006 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1006 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1006 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1006 = '#5f5f5f'
                        this.text_1006 = 'text-white'
                    } else{
                        this.r_1006 = ''
                    }
                } else if (rm_id === '1007') {
                    this.r_1007_stat = stat
                    if (stat === 'OCI') {
                        this.r_1007 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1007 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1007 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1007 = '#9fa1fd'
                        this.text_1007 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1007 = '#d22625'
                        this.text_1007 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1007 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1007 = '#de1fde'
                        this.text_1007 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1007 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1007 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1007 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1007 = '#5f5f5f'
                        this.text_1007 = 'text-white'
                    } else{
                        this.r_1007 = ''
                    }
                } else if (rm_id === '1008') {
                    this.r_1008_stat = stat
                    if (stat === 'OCI') {
                        this.r_1008 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1008 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1008 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1008 = '#9fa1fd'
                        this.text_1008 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1008 = '#d22625'
                        this.text_1008 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1008 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1008 = '#de1fde'
                        this.text_1008 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1008 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1008 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1008 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1008 = '#5f5f5f'
                        this.text_1008 = 'text-white'
                    } else{
                        this.r_1008 = ''
                    }
                } else if (rm_id === '1009') {
                    this.r_1009_stat = stat
                    if (stat === 'OCI') {
                        this.r_1009 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1009 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1009 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1009 = '#9fa1fd'
                        this.text_1009 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1009 = '#d22625'
                        this.text_1009 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1009 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1009 = '#de1fde'
                        this.text_1009 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1009 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1009 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1009 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1009 = '#5f5f5f'
                        this.text_1009 = 'text-white'
                    } else{
                        this.r_1009 = ''
                    }
                } else if (rm_id === '1010') {
                    this.r_1010_stat = stat
                    if (stat === 'OCI') {
                        this.r_1010 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1010 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1010 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1010 = '#9fa1fd'
                        this.text_1010 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1010 = '#d22625'
                        this.text_1010 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1010 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1010 = '#de1fde'
                        this.text_1010 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1010 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1010 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1010 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1010 = '#5f5f5f'
                        this.text_1010 = 'text-white'
                    } else{
                        this.r_1010 = ''
                    }
                } else if (rm_id === '1011') {
                    this.r_1011_stat = stat
                    if (stat === 'OCI') {
                        this.r_1011 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1011 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1011 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1011 = '#9fa1fd'
                        this.text_1011 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1011 = '#d22625'
                        this.text_1011 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1011 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1011 = '#de1fde'
                        this.text_1011 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1011 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1011 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1011 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1011 = '#5f5f5f'
                        this.text_1011 = 'text-white'
                    } else{
                        this.r_1011 = ''
                    }
                } else if (rm_id === '1012') {
                    this.r_1012_stat = stat
                    if (stat === 'OCI') {
                        this.r_1012 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1012 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1012 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1012 = '#9fa1fd'
                        this.text_1012 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1012 = '#d22625'
                        this.text_1012 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1012 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1012 = '#de1fde'
                        this.text_1012 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1012 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1012 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1012 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1012 = '#5f5f5f'
                        this.text_1012 = 'text-white'
                    } else{
                        this.r_1012 = ''
                    }
                } else if (rm_id === '1014') {
                    this.r_1014_stat = stat
                    if (stat === 'OCI') {
                        this.r_1014 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1014 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1014 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1014 = '#9fa1fd'
                        this.text_1014 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1014 = '#d22625'
                        this.text_1014 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1014 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1014 = '#de1fde'
                        this.text_1014 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1014 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1014 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1014 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1014 = '#5f5f5f'
                        this.text_1014 = 'text-white'
                    } else{
                        this.r_1014 = ''
                    }
                } else if (rm_id === '1015') {
                    this.r_1015_stat = stat
                    if (stat === 'OCI') {
                        this.r_1015 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1015 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1015 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1015 = '#9fa1fd'
                        this.text_1015 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1015 = '#d22625'
                        this.text_1015 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1015 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1015 = '#de1fde'
                        this.text_1015 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1015 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1015 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1015 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1015 = '#5f5f5f'
                        this.text_1015 = 'text-white'
                    } else{
                        this.r_1015 = ''
                    }
                } else if (rm_id === '1016') {
                    this.r_1016_stat = stat
                    if (stat === 'OCI') {
                        this.r_1016 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1016 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1016 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1016 = '#9fa1fd'
                        this.text_1016 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1016 = '#d22625'
                        this.text_1016 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1016 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1016 = '#de1fde'
                        this.text_1016 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1016 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1016 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1016 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1016 = '#5f5f5f'
                        this.text_1016 = 'text-white'
                    } else{
                        this.r_1016 = ''
                    }
                } else if (rm_id === '1017') {
                    this.r_1017_stat = stat
                    if (stat === 'OCI') {
                        this.r_1017 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1017 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1017 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1017 = '#9fa1fd'
                        this.text_1017 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1017 = '#d22625'
                        this.text_1017 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1017 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1017 = '#de1fde'
                        this.text_1017 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1017 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1017 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1017 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1017 = '#5f5f5f'
                        this.text_1017 = 'text-white'
                    } else{
                        this.r_1017 = ''
                    }
                } else if (rm_id === '1018') {
                    this.r_1018_stat = stat
                    if (stat === 'OCI') {
                        this.r_1018 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1018 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1018 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1018 = '#9fa1fd'
                        this.text_1018 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1018 = '#d22625'
                        this.text_1018 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1018 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1018 = '#de1fde'
                        this.text_1018 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1018 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1018 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1018 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1018 = '#5f5f5f'
                        this.text_1018 = 'text-white'
                    } else{
                        this.r_1018 = ''
                    }
                } else if (rm_id === '1019') {
                    this.r_1019_stat = stat
                    if (stat === 'OCI') {
                        this.r_1019 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1019 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1019 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1019 = '#9fa1fd'
                        this.text_1019 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1019 = '#d22625'
                        this.text_1019 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1019 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1019 = '#de1fde'
                        this.text_1019 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1019 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1019 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1019 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1019 = '#5f5f5f'
                        this.text_1019 = 'text-white'
                    } else{
                        this.r_1019 = ''
                    }
                } else if (rm_id === '1020') {
                    this.r_1020_stat = stat
                    if (stat === 'OCI') {
                        this.r_1020 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1020 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1020 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1020 = '#9fa1fd'
                        this.text_1020 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1020 = '#d22625'
                        this.text_1020 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1020 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1020 = '#de1fde'
                        this.text_1020 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1020 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1020 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1020 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1020 = '#5f5f5f'
                        this.text_1020 = 'text-white'
                    } else{
                        this.r_1020 = ''
                    }
                } else if (rm_id === '1022') {
                    this.r_1022_stat = stat
                    if (stat === 'OCI') {
                        this.r_1022 = '#ffe2e5'
                    } else if (stat === 'VCI'){
                        this.r_1022 = '#a2ff9f'
                    } else if (stat === 'VDI'){
                        this.r_1022 = '#e6e691'
                    } else if (stat === 'OCN'){
                        this.r_1022 = '#9fa1fd'
                        this.text_1022 = 'text-white'
                    } else if (stat === 'ODI' || stat === 'ODN'){
                        this.r_1022 = '#d22625'
                        this.text_1022 = 'text-white'
                    } else if (stat === 'VCI / VCN Block'){
                        this.r_1022 = '#aafaf9'
                    } else if (stat === 'Discrepency'){
                        this.r_1022 = '#de1fde'
                        this.text_1022 = 'text-white'
                    } else if (stat === 'VCN'){
                        this.r_1022 = '#ffdf9e'
                    } else if (stat === 'Due Out'){
                        this.r_1022 = '#e0e2e1'
                    } else if (stat === 'VDN'){
                        this.r_1022 = '#ffffa1'
                    } else if (stat === 'Maintenance'){
                        this.r_1022 = '#5f5f5f'
                        this.text_1022 = 'text-white'
                    } else{
                        this.r_1022 = ''
                    }
                }
            }
        },
        watch: {
        }
    }
    </script>
    <style scoped>
    .v-slide-group__content {
      justify-content: center;
    }
    </style>